import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import TextAreaComponent from "../components/textAreaComponent";
import InputImage from "../components/inputImage";
import Button from "../components/button";
import { callAPI } from "../networks/callApi";
import { BASE_URL } from "../commonservice/commonservice";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { toast } from "react-toastify";
import Table from "../components/table";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";

const Announcement = () => {
  const [userList, setUserList] = useState([]);
  const [listDetail, setListDetail] = useState();
  const navigate=useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    image: null,
    url: "",
    userId: [],
  });
  const [currentUserId, setCurrentUserId] = useState("");

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Image validation for only image files (JPEG, PNG, GIF, etc.)
  const handleImageChange = (file) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];

    if (file && validImageTypes.includes(file.type)) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      toast.success("Image selected successfully");
    } else {
      toast.error(
        "Invalid file type. Please upload an image (JPEG, PNG, GIF)."
      );
    }
  };

  const addUserId = () => {
    if (currentUserId.trim() !== "") {
      setUserList((prevList) => [...prevList, currentUserId]);
      setFormData((prevData) => ({
        ...prevData,
        userId: [...prevData.userId, currentUserId],
      }));
      setCurrentUserId("");
    }
  };

  const handleNotification = () => {
    // Validate form data
    if (!formData.title.trim()) {
      toast.error("Title is required");
      return;
    }
    if (!formData.message.trim()) {
      toast.error("Message is required");
      return;
    }
    if (formData.userId.length === 0) {
      toast.error("At least one User ID is required");
      return;
    }
    if (!formData.image) {
      toast.error("Image is required");
      return;
    }
    const data = new FormData();
    data.append("title", formData.title);
    data.append("body", `${formData.message}`);
    data.append("image", formData.image);

    formData?.userId?.forEach((item) => data.append("userIds", item));

    callAPI(
      BASE_URL + ENDPOINTS_API.SEND_NOTIFICATION,
      "POST",
      {
        headers: {
          "Content-type": "multipart/form-data",
        },
      },
      data
    )
      .then((res) => {
        console.log(res)
        toast.success("Notification Sent Successfully");
        navigate("/announcementList");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserList = () => {
    callAPI(BASE_URL + ENDPOINTS_API.GETUSERDETAILS, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setListDetail(res.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleUserList();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Name", accessor: "name" },
    { Header: "User Id", accessor: "userId" },
    { Header: "Email", accessor: "email" },
  ];

  let UserListData = [];
  listDetail &&
    listDetail?.forEach((item) => {
      UserListData.push({
        Sno: item.s_no,
        _id: item._id,
        name: item.name ? item.name : "Not Updated",
        userId: item._id,
        email: item.email,
      });
    });

  const UserListHeading = {
    heading: "User List",
  };

  return (
    <div>
      <div className="ml-2 mt-4 w-[80%] bg-darkBlue px-4 pt-2 pb-10 rounded-[10px]">
        <div className="text-[1.5rem] text-white">ANNOUNCEMENT</div>
        <div className="text-white text-[.5rem]">Announcement for user</div>

        <div className="flex w-[30vw]">
          <InputComponent
            lable={"UserId*"}
            placeholder={"Enter UserId"}
            value={currentUserId}
            onChange={(value) => setCurrentUserId(value)}
          />
        </div>
        <Button onClick={addUserId} lable={"Add UserId"} />

        <div className="mt-2">
          <p className="text-white">Added User IDs: {userList.join(", ")}</p>
        </div>

        <InputComponent
          lable={"Title*"}
          placeholder={"Enter your title"}
          value={formData.title}
          onChange={(value) => handleInputChange("title", value)}
        />
        <TextAreaComponent
          lable={"Message*"}
          placeholder={"Enter your message"}
          mt={"mt2"}
          value={formData.message}
          onChange={(value) => handleInputChange("message", value)}
        />
        <InputImage
          lable={"Image"}
          setFile={handleImageChange}
          setFilePreview={(preview) =>
            handleInputChange("imagePreview", preview)
          }
        />
      </div>

      <div className="ml-5 flex">
        <Button onClick={handleNotification} lable={"Add Announcement"} />
      </div>

      <Table
        columns={columns}
        data={UserListData}
        titleData={UserListHeading}
        headingSize={"text-[1rem]"}
      />
    </div>
  );
};

export default Announcement;
