import React, { useEffect, useState } from "react";
import {
  handleCancellChallenge,
  handleGetLudoChallenges,
} from "../../networks/adminApi";
import Table from "../../components/table";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
} from "@mui/material";

const CancelChallenge = ({ challengeId, setChallengeCancell }) => {
  const [open, setOpen] = useState(false);

  const handleCancel = async () => {
    const res = await handleCancellChallenge({ challengeId });

    if (res?.success) {
      toast.success("Challenge Cancelled Successfully");
      setChallengeCancell(true);
    } else {
      toast.error("Deleting Challenge Failed");
    }
    setOpen(false);
  };

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpenModal}>
        <MdDelete />
      </button>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to Cancell this challenge?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton
            variant="contained"
            onClick={handleCancel}
            color="secondary"
          >
            Yes
          </MuiButton>
          <MuiButton
            variant="contained"
            onClick={handleCloseModal}
            color="primary"
          >
            No
          </MuiButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllLudoChallenges = () => {
  const [ludoChallengs, setLudoChallenges] = useState("");
  const [challengeCancelled, setChallengeCancell] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetLudoChallenges(currentPage);

      if (res?.data) {
        setLudoChallenges(res?.data?.challenges);
        setChallengeCancell(false);
        setTotalPages(res.data?.totalPages)
      }
    };

    fetchData();
  }, [challengeCancelled,currentPage]);

  const columns = [
    { Header: "S.No", accessor: "Sno" },

    { Header: "Game Name", accessor: "roomname" },
    { Header: "Room Id", accessor: "roomId" },
    { Header: "Entry Fee", accessor: "entryFee" },
    {
      Header: "Get Detail",
      accessor: "getDetails",
    },
    { Header: "Winning Amount", accessor: "winningamount" },
    // { Header: "Status", accessor: "status" },
    { Header: "Delete", accessor: "cancell" },
  ];

  const challengesData = [];
  ludoChallengs &&
    ludoChallengs?.forEach((item) => {
      challengesData.push({
        Sno: item.s_no,
        roomname: item.appRoomId,
        roomId: item.roomId,
        entryFee: item.entryFee,
        getDetails: (
          <Button
            onClick={() => navigate(`/getViewDetailsGame/${item._id}`)}
            lable={"getDetail"}
          />
        ),
        winningamount: item.prizeMoney,

        cancell: (
          <CancelChallenge
            challengeId={item._id}
            setChallengeCancell={setChallengeCancell}
          />
        ),
      });
    });

  const tableHeading = {
    heading: "All Ludo Challenges",
  };

  return (
    <div className="pt-4">
      {challengesData.length > 0 ? (
        <Table
          columns={columns}
          data={challengesData}
          titleData={tableHeading}
          headingSize={"text[1rem]"}
        />
      ) : (
        <div className="text-white flex justify-center mt-10">
          No Data Found...
        </div>
      )}

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default AllLudoChallenges;
