import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button as MUIButton } from "@mui/material";
import {
  handleDeleteCoupon,
  handleGetCouponByAdmin,
} from "../networks/adminApi";
import Table from "../components/table";
import LinkComponent from "../components/linkComponent";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import Pagination from "../components/pagination";

const CouponAction = ({ id, handleOpenDeleteModal }) => {
  return (
    <div className="flex justify-center">
      <Link to={`/editCoupon/${id}`} className="text-white mr-2">
        <FaEdit />
      </Link>
      <button className="text-white" onClick={() => handleOpenDeleteModal(id)}>
        <MdDelete />
      </button>
    </div>
  );
};

const Coupon = () => {
  const [listofCoupons, setListpOfCoupons] = useState([]);
  const [couponDeleted, setCouponDeleted] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState(null);

  const fetchData = async () => {
    const res = await handleGetCouponByAdmin(currentPage);
    console.log(res, "DAtaCoupon");
    if (res?.success) {
      setListpOfCoupons(res?.data?.coupons);
      setCouponDeleted(false);
      setTotalPages(res?.data?.totalPages);
    }
  };
  useEffect(() => {
    fetchData();
  }, [couponDeleted, currentPage]);

  const handleOpenDeleteModal = (id) => {
    setSelectedCouponId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedCouponId(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedCouponId) {
      const res = await handleDeleteCoupon({ id: selectedCouponId });
      if (res?.success) {
        setCouponDeleted(true);
        toast.success("Coupon Deleted Successfully");
      }
      handleCloseDeleteModal();
    }
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },

    { Header: "Min Amount", accessor: "minAmount" },
    { Header: "Max Amount", accessor: "maxAmount" },
    { Header: "Match Counts", accessor: "matchCounts" },
    { Header: "Action", accessor: "action" },
  ];

  const couponList = [];

  listofCoupons &&
    listofCoupons.forEach((item, index) => {
      couponList.push({
        s_no: item.s_no,
        minAmount: item.minAmount,
        maxAmount: item.maxAmount,
        matchCounts: item.matchCounts,
        action: (
          <CouponAction
            id={item._id}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        ),
      });
    });

  const tableHeading = {
    heading: "Scratch card List",
  };

  return (
    <div className="mx-4 mt-4 pb-4">
      <LinkComponent path={"/addCoupon"} lable={"Add Scratch Card"} />
      <LinkComponent path={"/allCoupons"} lable={"Scratch Card List"} ml={"ml-2"} />

      {couponList.length > 0 ? (
        <Table
          columns={columns}
          data={couponList}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      ) : (
        <div className="text-white flex justify-center mt-10">
          No Data Found...
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-title"
        aria-describedby="delete-confirmation-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="delete-confirmation-title"
            variant="h6"
            component="h2"
          >
            Are you sure you want to delete this coupon?
          </Typography>
          <Box mt={2} display="flex" justifyContent="flex-end" gap={"10px"}>
            <MUIButton
              variant="contained"
              color="primary"
              onClick={handleDeleteConfirm}
            >
              Yes
            </MUIButton>
            <MUIButton
              variant="contained"
              color="secondary"
              onClick={handleCloseDeleteModal}
            >
              No
            </MUIButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Coupon;
