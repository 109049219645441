import React, { useState, useEffect } from "react";
import "./style.css"; // Make sure to add appropriate styles in this CSS file
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/pagination";
import Table from "../../components/table";


const Index = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [supportList, setSupportList] = useState([]);

 

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Mobile Number", accessor: "mobilenumber" },
    { Header: " User Name", accessor: "name" },
    { Header: "Upi Id", accessor: "upiid" },
  
  ];

  const feedbackList = supportList
    .map((item, index) => ({
      s_no: index + 1,
      mobilenumber: item.mobilenumber,
      name: item.name,
      upiid: item.upiid,
    
    }));

  const tableHeading = {
    heading: "Withdrawl List",
  };

 

  return (
    <div>
      <div className="commission-slab-container">
        
          <Table
            columns={columns}
            data={feedbackList}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
        
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Index;
