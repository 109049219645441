import React, { useEffect, useState } from "react";
import AllGames from "./dataAddScreen/allGames";
import LinkComponent from "../components/linkComponent";
import AllBanner from "./dataAddScreen/allBanner";
import Button from "../components/button";
import { useLocation } from "react-router-dom";

const Game = () => {
  const [selectedCategory, setSelectedCategory] = useState("E-sport");
  const location = useLocation();

  const { yourStateValue } = location.state || {};
  console.log(yourStateValue, "yourStateValue");

  useEffect(() => {
    if (yourStateValue === "Banner") {
      setSelectedCategory(yourStateValue);
    }
  }, [yourStateValue]);

  const listShown = [
    { _id: 1, text: "E-sport" },
    { _id: 2, text: "Banner" },
  ];

  return (
    <div className="min-h-[100vh] mt-0 bg-blue mx-4">
      <div className="flex mt-4">
        {listShown.map((item, index) => {
          return (
            <Button
              onClick={() => setSelectedCategory(item.text)}
              key={item._id}
              lable={item.text}
              ml={index > 0 ? "ml-2" : 0}
            ></Button>
          );
        })}
      </div>
      <div className="flex justify-end my-2">
        {selectedCategory === "E-sport" && (
          <LinkComponent
            lable={"Add E-sport"}
            path={"/addNew/E-sport"}
          ></LinkComponent>
        )}
        {selectedCategory === "Banner" && (
          <LinkComponent
            lable={"Add Banner"}
            path={"/addNew/Banner"}
          ></LinkComponent>
        )}
      </div>

      {selectedCategory === "E-sport" && <AllGames />}
      {selectedCategory === "Banner" && <AllBanner />}
    </div>
  );
};

export default Game;
