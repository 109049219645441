import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  handleDeleteEventById,
  handleGameById,
  handleEditGameEvent,
  handleCancelEventById,
} from "../../networks/adminApi";
import Table from "../../components/table";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import SelectComponet from "../../components/selectComponet";
import Button from "../../components/button";
import Pagination from "../../components/pagination";
import TopPlayer from "../topPlayer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const DeleteEvent = ({ id, setDeletedEvent }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handelDeleteEvent = async () => {
    const res = await handleDeleteEventById({ id });

    if (res?.success) {
      setDeletedEvent(true);
      toast.success("Event deleted Successfully");
      handleClose();
    } else {
      toast.error("Event delete failed");
    }
  };

  return (
    <>
      <button onClick={handleClickOpen}>
        <MdDelete />
      </button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this event?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handelDeleteEvent}
            color="error"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CancelEvent = ({ id, setDeletedEvent, isCancelled }) => {
  const [Cancelled, setCanceled] = useState(false);

  const handelCancelEvent = async () => {
    const res = await handleCancelEventById({ id });
    console.log(id, "gggggggggggggggggg");

    if (res?.success) {
      setDeletedEvent(true);
      setCanceled(true);
      toast.success("Event Cancel Successfully");
    } else {
      toast.error("Event Cancel failed");
    }
  };

  return (
    <button
      style={{ color: isCancelled ? "red" : "white" }}
      onClick={handelCancelEvent}
      disabled={isCancelled}
    >
      {isCancelled ? "Canceled" : "Cancel"}
    </button>
  );
};

const GetDescription = ({ id }) => {
  return <Link to={`/getDescription/${id}`}>Check Participant</Link>;
};

const EditGameEventById = ({ id, name }) => {
  return (
    <div className="flex justify-center">
      <Link to={`/editGameEvent/${name}/${id}`}>
        <FaEdit />
      </Link>
    </div>
  );
};

const AddRoomId = ({ id, participants }) => {
  return (
    <>
      <Link to={`/addRoomId/${id}`} className="flex justify-center">
        <MdModeEditOutline />
      </Link>
    </>
  );
};

const EventTypeComponent = ({ type, item, roomId }) => {
  const [value, selectedValue] = useState(type);
  const [updatedEvent, setUpdatedEvent] = useState(item);

  const [updateByRoomId, setUpdateByRoomId] = useState(item);

  const listData = ["ongoing", "completed"];

  const updateEventType = async () => {
    if (type === "upcoming" && roomId) {
      const res = await handleEditGameEvent({ updateByRoomId });
      console.log(res, "State change");
    }
  };

  useEffect(() => {
    updateEventType();
    setUpdateByRoomId({ ...item, eventType: "ongoing" });
  }, [roomId, type]);

  useEffect(() => {
    setUpdatedEvent({ ...item, eventType: value, id: item._id });
  }, [value]);

  const handleEditEventType = async () => {
    const res = await handleEditGameEvent(updatedEvent);
    console.log(res, "jjjjjj");
    if (res?.success) {
      toast.success("Event Status successfully");
    } else {
      toast.error(res?.message);
    }
  };

  return (
    <div className="z-20 flex justify-between items-center">
      {/* {type} */}
      <SelectComponet
        value={value}
        onSelect={selectedValue}
        data={listData}
        width={"w-[100px]"}
      />
      <Button
        lable={"submit"}
        mt={"mt-2"}
        ml={"ml-2"}
        onClick={() => handleEditEventType()}
      />
    </div>
  );
};

const GetEventList = () => {
  const { id } = useParams();
  const [eventList, setEventList] = useState([]);
  const [deletedEvent, setDeletedEvent] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGameById({ id });

      console.log(res, "Event data");

      if (res?.data) {
        setEventList(res?.data);
        setDeletedEvent(false);
      }
    };
    fetchData();
  }, [id, deletedEvent]);

  const columns = [
    { Header: "S.No", accessor: "s_no" },

    { Header: "Game Name", accessor: "gameName" },
    { Header: "Title", accessor: "title" },
    { Header: "Entry Fee", accessor: "entryFee" },
    // { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Date", accessor: "date" },
    { Header: "Map", accessor: "map" },
    { Header: "Per Kill", accessor: "perKill" },
    { Header: "Prize", accessor: "prize" },
    { Header: "Version", accessor: "version" },
    { Header: "Description", accessor: "description" },
    { Header: "Add RoomId", accessor: "addRoomId" },
    { Header: "RoomId", accessor: "roomid" },
    { Header: "Cancel", accessor: "cancelEvent" },
    { Header: "Event Type", accessor: "eventType" },
    { Header: "Delete", accessor: "delete" },
    { Header: "Edit Event", accessor: "editEvent" },
  ];

  const eventData = [];
  eventList &&
    eventList.forEach((item, index) => {
      eventData.push({
        s_no: index + 1,

        id: item._id,
        gameName: item.gameName,
        title: item.title,
        eventType: (
          <EventTypeComponent
            type={item.eventType}
            item={item}
            roomId={item.roomId}
          />
        ),
        entryFee: item.entryFee || "free",

        // imgPreview: (
        //   <div className="flex justify-center">
        //     <img
        //       src={item.image}
        //       className="h-10 w-10 object-contain"
        //       alt="img"
        //     />
        //   </div>
        // ),
        date: item.date,
        map: item.map,
        perKill: item.perKill,
        prize: item.prize,
        version: item.version,
        editEvent: <EditGameEventById id={item._id} name={item.gameName} />,
        description: <GetDescription id={item._id} />,
        addRoomId: (
          <AddRoomId id={item._id} participants={item.participantCount} />
        ),
        roomid: item.roomId || "Not Updated",
        cancelEvent: (
          <CancelEvent
            id={item._id}
            setDeletedEvent={setDeletedEvent}
            isCancelled={item.isCancelled}
          />
        ),
        delete: <DeleteEvent id={item._id} setDeletedEvent={setDeletedEvent} />,
      });
    });

  const gameEventHeading = {
    heading: "All Events",
  };

  return (
    <div className="mx-4">
      {eventData.length > 0 ? (
        <Table
          columns={columns}
          data={eventData}
          titleData={gameEventHeading}
          headingSize={"text-[1rem]"}
        />
      ) : (
        <div className="text-white flex  justify-center mt-10">
          {/* No Data Found... */}
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default GetEventList;
