import "./App.css";
import Header from "./components/header";
import Dashboard from "./pages/dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Game from "./pages/game";
import CompleteMatchList from "./pages/completeMatchList";
import BulkAction from "./pages/bulkAction";
import OffLineTransaction from "./pages/offlinetransaction";
import AddNewGame from "./pages/dataAddScreen/newGame";
import AddNewMatch from "./pages/dataAddScreen/newMatch";
import UsersList from "./pages/usersList";
import UserDetails from "./pages/userDetails";
import AllGames from "./pages/dataAddScreen/allGames";
import GetEventList from "./pages/dataAddScreen/getEvent";
import GetDescription from "./pages/dataAddScreen/getDescription";
import ParticipantDetails from "./pages/dataAddScreen/participantDetails";
import Scratchcard from "./pages/dataAddScreen/scratchcard";
import EditGameEvent from "./pages/dataAddScreen/editGameEvent";
import Login from "./pages/auth/login";
import ForgotPassword from "./pages/auth/forgotPassword";
import ChangePassword from "./pages/auth/changePassword";
import ProtectedRoute from "./pages/auth/protectedRoute";
import { useEffect } from "react";
import { getAdminProfile } from "./networks/adminApi";
import { useDispatch } from "react-redux";
import {
  setAdminAuthenticated,
  setUsersProfile,
} from "./store/reducer/usersReducer";

import EditProfile from "./pages/editProfile";
import UpdatePassword from "./pages/updatePassword";
import AddLudoChallenge from "./pages/dataAddScreen/addLudoChallenge";
import Announcement from "./pages/announcement";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import EditBanner from "./pages/dataAddScreen/editBanner";
import TopPlayer from "./pages/topPlayer";
import { AuthProvider } from "./hooks/authContext";
import Coupon from "./pages/coupon";
import AddCoupon from "./pages/addCoupon";
import EditCoupon from "./pages/editCoupon";
import AddRoomId from "./pages/dataAddScreen/addRoomId";
import AllLudoChallenges from "./pages/dataAddScreen/allLudoChallenges";
import MatchRules from "./pages/matchRules";
import AddMatchRules from "./pages/dataAddScreen/addMatchRules";
import GetViewDetailsGame from "./pages/dataAddScreen/getViewDetailsGame";
import TransactionList from "./pages/transactionList";
import AnnouncementList from "./pages/announcementList";
import CouponList from "./pages/couponList";
import UserListBlocked from "./pages/userListBlocked";
import ReferralList from "./pages/referralList";
import AllCouponList from "./pages/allCouponList";
import Transaction from "./pages/TransactionlistbyId";
import Support from "./pages/Support/index";
import Addticketissue from "./pages/Support/AddticketIssue/index";
import AddIssue from "./pages/Support/AddIssue/index";
import EditMatchRule from "./pages/Editmatchrule";
import VideoLink from "./pages/VideoLink/index";
import TicketReply from "./pages/Support/TicketReply";
import Redeemedcoupon from "./pages/redeemedcoupon";
import ReferalEarning from "./pages/referalearning";
import Errorchallanges from "./pages/errorchallanges"
import AnnouncementShowUser from "./pages/announcementShowUser"
import Withdrawl from "./pages/Withdrawl/index";

function App() {
  const dispatch = useDispatch();

  const authenticated = localStorage.getItem("token");
  console.log(authenticated, "kkkkkkkkkkkk");
  useEffect(() => {
    if (authenticated) {
      const fetchData = async () => {
        const res = await getAdminProfile();
        console.log(res, "response");
        if (res?.success) {
          dispatch(setAdminAuthenticated(true));
          dispatch(setUsersProfile(res?.data));
        }
      };
      fetchData();
    }
  }, []);

  const routeData = [
    { _id: 1, path: "/", component: <Dashboard /> },
    { _id: 2, path: "/game", component: <Game /> },
    { _id: 3, path: "/completeMatchList", component: <CompleteMatchList /> },
    { _id: 4, path: "/bulkAction", component: <BulkAction /> },
    { _id: 5, path: "/offLineTransaction", component: <OffLineTransaction /> },
    { _id: 6, path: "/addNew/:add", component: <AddNewGame /> },
    { _id: 7, path: "/addnewMatch/:name/:id", component: <AddNewMatch /> },
    { _id: 8, path: "/userList", component: <UsersList /> },
    { _id: 9, path: "/userDetails/:id", component: <UserDetails /> },
    { _id: 10, path: "/allGame", component: <AllGames /> },
    { _id: 11, path: "/getEvent/:id", component: <GetEventList /> },
    { _id: 12, path: "/getDescription/:id", component: <GetDescription /> },
    { _id: 12, path: "/transactionlistbyid/:id", component: <Transaction /> },
    {
      _id: 12,
      path: "/getViewDetailsGame/:id",
      component: <GetViewDetailsGame />,
    },
    {
      _id: 13,
      path: "/getParticipantDetials/:id",
      component: <ParticipantDetails />,
    },
    { _id: 14, path: "/scratchCard", component: <Scratchcard /> },
    { _id: 15, path: "/editGameEvent/:name/:id", component: <EditGameEvent /> },
    { _id: 16, path: "/editprofile", component: <EditProfile /> },
    { _id: 17, path: "/updatePassword", component: <UpdatePassword /> },
    { _id: 18, path: "/addLudoChallenge", component: <AddLudoChallenge /> },
    { _id: 19, path: "/announcement", component: <Announcement /> },
    { _id: 20, path: "/editBanner/:id", component: <EditBanner /> },
    { _id: 21, path: "/topPlayer", component: <TopPlayer /> },
    { _id: 22, path: "/coupon", component: <Coupon /> },
    { _id: 23, path: "/addCoupon", component: <AddCoupon /> },
    { _id: 24, path: "/editCoupon/:id", component: <EditCoupon /> },
    { _id: 25, path: "/addRoomId/:id", component: <AddRoomId /> },
    { _id: 26, path: "/addLudoList", component: <AllLudoChallenges /> },
    { _id: 27, path: "/matchRules", component: <MatchRules /> },
    { _id: 28, path: "/addMatchRules", component: <AddMatchRules /> },
    { _id: 29, path: "/transaction", component: <TransactionList /> },
    { _id: 30, path: "/announcementList", component: <AnnouncementList /> },
    { _id: 31, path: "/couponList/:id", component: <CouponList /> },
    { _id: 32, path: "/blocked_user", component: <UserListBlocked /> },
    { _id: 33, path: "/referral", component: <ReferralList /> },
    { _id: 35, path: "/userCouponsData", component: <AllCouponList /> },
    { _id: 37, path: "/allCoupons", component: <AllCouponList /> },
    { _id: 38, path: "/support", component: <Support /> },
    { _id: 39, path: "/AddTicketIssue", component: <Addticketissue /> },
    { _id: 40, path: "/ticketreply/:id", component: <TicketReply /> },
    { _id: 41, path: "/AddIssue", component: <AddIssue /> },
    { _id: 42, path: "/editMatchRule/:id", component: <EditMatchRule /> },
    { _id: 43, path: "/videolink", component: <VideoLink /> },
    { _id: 44, path: "/redeemedlist/:id", component: <Redeemedcoupon /> },
    { _id: 45, path: "/referal/:id", component: <ReferalEarning /> },
    { _id: 46, path: "/errorchallanges", component: <Errorchallanges /> },
    { _id: 47, path: "/announcementshowuser/:id", component: <AnnouncementShowUser /> },
    { _id: 47, path: "/withdrawl", component: <Withdrawl /> },
  ];

  return (
    <>
      <AuthProvider>
        <div className="h-[100vh]">
          <Header />
          <div className="bg-blue min-h-[100vh] relative ">
            <div className="z-0 pt-16">
              <Routes>
                {routeData.map((item) => {
                  return (
                    <Route
                      key={item._id}
                      path={item.path}
                      element={<ProtectedRoute element={item.component} />}
                    />
                  );
                })}
                <Route path="/login" Component={Login} />
                <Route path="/forgotPassword" Component={ForgotPassword} />
                <Route path="/chanagePassword" Component={ChangePassword} />
              </Routes>
              <ToastContainer />
            </div>
          </div>
        </div>
      </AuthProvider>
    </>
  );
}

export default App;
