import React from "react";

const TextAreaComponent = ({
  lable,
  placeholder,
  mt = "mt-4",
  value,
  onChange,
}) => {
  return (
    <div className={`${mt}`}>
      <div className="text-white">{lable}</div>
      <textarea
        className="bg-blue text-white focus:outline-none mt-2 px-[10px] py-[5px] border-[1px] border-grey rounded-[4px] w-[100%] "
        rows="5"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default TextAreaComponent;
