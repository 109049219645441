import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Table from "../components/table";
import { getUsersList, handleUserDelete } from "../networks/adminApi";
import moment from "moment/moment";
import { toast } from "react-toastify";
import LinkComponent from "../components/linkComponent";
import Pagination from "../components/pagination";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button as MuiButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";

const BlockUnblockModal = ({ isOpen, onClose, onConfirm, user }) => {
  const [action, setAction] = useState("");

  const handleSuspendAction = () => {
    onConfirm(user._id, action);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Suspend/Unsuspend User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select an action for {user?.first_name}:
        </DialogContentText>
        <FormControl fullWidth>
          <InputLabel id="action-label">Action</InputLabel>
          <Select
            labelId="action-label"
            value={action}
            onChange={(e) => setAction(e.target.value)}
            label="Action"
          >
            <MenuItem value="suspend">Suspend</MenuItem>
            <MenuItem value="unsuspend">Unsuspend</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={onClose}>Cancel</MuiButton>
        <MuiButton onClick={handleSuspendAction} color="primary">
          Confirm
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

const ActionButton = ({ user, refreshData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleConfirm = async (userId, action) => {
    try {
      const d = await handleUserDelete(userId, action);
      d?.response?.data?.message
        ? toast.error(d.response.data.message)
        : toast.success(d?.data?.message);
      refreshData();
    } catch (error) {
      toast.error("An error occurred while updating the user status.");
    }
  };

  return (
    <div className="flex items-center">
      <button
        onClick={() => setIsModalOpen(true)}
        className="text-blue-500 hover:text-blue-700"
      >
        <FaEdit size={20} style={{ marginLeft: "15px" }} />
      </button>
      <BlockUnblockModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirm}
        user={user}
      />
    </div>
  );
};

const UsersList = () => {
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [amount, setAmount] = useState();

  const [id, setId] = useState("");

  const [open, setOpen] = useState(false);

  const handleChange = (e) => {
    setStatus(e.target.value);
  };
  const handleClickOpen = (id) => {
    setId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlelist = (id) => {
    navigate(`/redeemedlist/${id}`);
  };

  const handleReferal = (id) => {
    navigate(`/referal/${id}`);
  };

  const fetchData = async () => {
    try {
      const res = await getUsersList(currentPage);
      console.log(res?.users, "call");
      if (res) {
        setUsersList(res?.users);
        setTotalPages(res?.totalPages);
      }
    } catch (error) {
      toast.error("An error occurred while fetching the users list.");
    }
  };

  console.log(usersList, "uselist");

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handleupdateamount = () => {
    if (amount < 1) {
      toast.error("Value Cannot be negative or zero");
      return;
    }
    if (!status) {
      toast.error("Please select an action (Increment/Decrement)");
      return;
    }
    callAPI(
      BASE_URL + ENDPOINTS_API.UPDATE_BALANCE,
      "PUT",
      {
        "Content-type": "application/json",
      },
      JSON.stringify({
        amount: Number(amount),
        userId: id,
        action: status, // Sending increment or decrement as action
      })
    )
      .then((res) => {
        console.log(res.data);
        toast.success("Amount Updated successfully");
        fetchData();
           setAmount("");
           setStatus("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "User Id", accessor: "userId" },
    { Header: "Edit Amount", accessor: "editamount" },

    { Header: "Date of Birth", accessor: "date" },
    { Header: "Redeemed Coupon", accessor: "redeemedcoupon" },
    { Header: "Coupon", accessor: "coupon" },
    { Header: "Total Balance", accessor: "balance" },
    { Header: "ScratchCard Earnings", accessor: "scratchCard" },
    { Header: "Referal Earnings", accessor: "referalearning" },
    { Header: "E-sport Played", accessor: "esport" },
    { Header: "Ludo Played", accessor: "ludoPlayed" },
    { Header: "Winning Amount", accessor: "winningamount" },
    {
      Header: "Created At",
      accessor: "createdAt",
    },
    { Header: "Status", accessor: "status" },
    { Header: "Action", accessor: "action" },
  ];

  const registrationObj = {
    heading: "New Registration",
    subHeading: "Manage registered users here",
  };

  let registrationData = [];
  usersList &&
    usersList?.forEach((item) => {
      registrationData.push({
        Sno: item.s_no,
        _id: item._id,
        name: item.name ? item.name : "Not Updated",
        email: item.email,
        userId: item._id,
        editamount: (
          <button
            onClick={() => handleClickOpen(item._id)}
            style={{
              width: "8vw",
              background: "#2657cf",
              height: "4.5vh",
              borderRadius: "5px",
            }}
          >
            Edit Amount
          </button>
        ),
        coupon: (
          <div className="w-[100px]">
            <LinkComponent
              path={`/couponList/${item._id}`}
              lable={"Coupon List"}
            />
          </div>
        ),
        mobile: item.mobile ? item.mobile : "Not Updated",
        date: item.dob ? item.dob : "Not Updated",
        redeemedcoupon: (
          <button
            onClick={() => handlelist(item._id)}
            style={{
              border: "1px solid",
              width: "12vw",
              height: "5vh",
              borderRadius: "4px",
            }}
          >
            Redeemed Coupon
          </button>
        ),
        balance: item.bonusAmount + item.depositAmount + item.winningAmount,
        scratchCard: item.totalCouponMoney,
        referalearning: (
          <button
            onClick={() => handleReferal(item._id)}
            style={{
              border: "1px solid",
              width: "10vw",
              height: "5vh",
              borderRadius: "4px",
            }}
          >
            Referal Earning
          </button>
        ),
        esport: item.totalSportsGames,
        ludoPlayed: item.totalLudoGame,
        winningamount: item.winningAmount,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY"),
        status: (
          <span
            style={{
              color: item.isBlocked ? "red" : "lightgreen",
              padding: "4px 8px",
              borderRadius: "4px",
              fontWeight: "bold",
            }}
          >
            {item.isBlocked ? "Inactive" : "Active"}
          </span>
        ),
        action: <ActionButton user={item} refreshData={fetchData} />,
      });
    });

  return (
    <div>
a        <div className="mx-4 pb-4 pt-4">
          <LinkComponent path={"/blocked_user"} lable={"Blocked Users"} />
          <Table
            columns={columns}
            data={registrationData}
            titleData={registrationObj}
            headingSize={"text-[1rem]"}
          />
        </div>
     
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle style={{ textAlign: "center" }}>Edit Amount</DialogTitle>
        <DialogContent style={{ width: "30vw" }}>
          <div className="dialog_inputs">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Amount</label>
              <input
                style={{ border: "1px solid" }}
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </div>

          <TextField
            style={{ width: "26vw", marginTop: "10px" }}
            select
            fullWidth
            label="Select Amount Type"
            value={status}
            onChange={handleChange}
          >
          
            <MenuItem value="increment">Increment</MenuItem>
            <MenuItem value="decrement">Decrement</MenuItem>
          </TextField>
        </DialogContent>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <button
            onClick={handleupdateamount}
            style={{
              width: "8vw",
              height: "5vh",
              background: "#226fcf",
              color: "white",
              borderRadius: "5px",
            }}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default UsersList;
