import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
const GraphComponent = ({ details }) => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "",
        data: details.data,
        borderColor: `${details.color}`,
        backgroundColor: `${details.bgColor}`,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        lineTension: 0.3,
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      margin: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        display: false,
        title: {
          display: false,
          text: "Month",
        },
      },
      y: {
        display: false,
        title: {
          display: false,
          text: "Value",
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not needed
      },
    },
  };

  return (
    <div className="bg-darkBlue mt-4 ml-2 h-32">
      <div className="flex flex-row items-baseline ml-2 w-[150x]">
        <div className="text-white">{details.value}</div>
        <div className={`ml-4 text-[.5rem] `} style={{ color: details.color }}>
          {details.type}
        </div>
      </div>
      <div className="h-[110px] w-[250px] overflow-scroll hide-scrollbar ">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default GraphComponent;
