import React from "react";
import Table from "../components/table";
import Pagination from "../components/pagination";
import search from "../assets/image/search.png";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
const CompleteMatchList = () => {
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Title", accessor: "title" },
    { Header: "Time", accessor: "time" },
    { Header: "Entry Fee", accessor: "entryFee" },
    { Header: "Match Type", accessor: "matchtype" },
    { Header: "Action", accessor: "action" },
  ];

  const matchListData = [
    {
      id: 1,
      title: "AAAAAA",
      time: "12:10 PM",
      entryFee: "100",
      matchtype: "Solo",
      action: "Required",
    },
    {
      id: 2,
      title: "AAAAAA",
      time: "12:10 PM",
      entryFee: "100",
      matchtype: "Solo",
      action: "Required",
    },
    {
      id: 3,
      title: "AAAAAA",
      time: "12:10 PM",
      entryFee: "100",
      matchtype: "Solo",
      action: "Required",
    },
  ];

  const matchListObj = {
    heading: "Complete Match List",
    subHeading:
      "Complete match list here, Denerate result, position and winner",
    viewAll: "View All",
  };
  return (
    <div>
      <div className="flex flex-row items-center justify-between mx-4 py-4">
        <div className="flex flex-row items-center">
          <div className="text-white text-[.5rem]">Show</div>
          <div className="h-[15px] border-[1px] w-[75px] border-grey mx-1 relative rounded-[4px] flex items-center">
            <div></div>
            {false ? (
              <FaChevronUp className="h-2 w-2 text-white absolute right-1 " />
            ) : (
              <FaChevronDown className="h-2 w-2 text-white absolute right-1 " />
            )}
          </div>
          <div className="text-white text-[.5rem]">entries</div>
        </div>
        <img src={search} className="h-3 w-3" alt="search img" />
      </div>
      <div className="mx-4">
        <Table
          titleData={matchListObj}
          columns={columns}
          data={matchListData}
        />
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default CompleteMatchList;
