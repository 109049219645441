import React, { useEffect, useState } from "react";
import Table from "../components/table";
import {
  handleGetGametopPlayers,
  handleGetLudoTopPlayer,
} from "../networks/adminApi";
import Button from "../components/button";
import Pagination from "../components/pagination";

const TopPlayer = () => {
  const [topGamePlayer, setTopGamePlayer] = useState([]);
  const [topLudoPlayer, setTopLudoPlayer] = useState([]);
  const [selectedTab, setSelectedTab] = useState("GAME");
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState();

  const handleGameTopPlayer = async () => {
    const res = await handleGetGametopPlayers(currentPage);
    console.log(res, "GAmeTOPPLAYERS");
    if (res?.data) {
      setTopGamePlayer(res?.data);
      setTotalPages(res.data.totalPages)
    }
  };

  const handleLudoTopPlayer = async () => {
    const res = await handleGetLudoTopPlayer(currentPage);
    console.log(res, "LudoTopPlayer");
    if (res?.data) {
      setTopLudoPlayer(res?.data);
      setTotalPages(res.data.totalPages)
    
    }
  };

  useEffect(() => {
    handleGameTopPlayer();
    handleLudoTopPlayer();
  }, [currentPage]);

  const columns = [
    {
      Header: "S.No",
      accessor: "s_no",
    },
    {
      Header: "Total Earnings",
      accessor: "totalEarnings",
    },
    {
      Header: "User Id",
      accessor: "userId",
    },
    {
      Header: "User Name",
      accessor: "username",
    },
    {
      Header: "Position",
      accessor: "position",
    },
    {
      Header: "Winning Amount",
      accessor: "winningamount",
    },
    {
      Header: "Kills",
      accessor: "kills",
    },
  ];
  const column = [
    {
      Header: "S.No",
      accessor: "s_no",
    },
    {
      Header: "Total Earnings",
      accessor: "totalEarnings",
    },
    {
      Header: "User Name",
      accessor: "username",
    },
    {
      Header: "Winning Amount",
      accessor: "winningamount",
    },
   
   
  ];

  // Map the topPlayer state to topperData
  const topperGameData = [];
  topGamePlayer &&
    topGamePlayer?.forEach((item) => {
      topperGameData.push({
        id: item._id,
        s_no: item.s_no,
        totalEarnings: item.totalEarnings,
        userId: item.userId,
        username: item?.participantData[0].username,
        position: item.participantData[0].position || "null",
        winningamount: item.participantData[0].winningAmount,
        kills: item.participantData[0].kills || "null",
      });
    });

  const tableHeadingGame = {
    heading: "Top Game Player",
  };
  const tableHeadingLudo = {
    heading: "Top Ludo Player",
  };

  const topperLudoData = [];

  topLudoPlayer &&
    topLudoPlayer?.forEach((item) => {
      topperLudoData.push({
        id: item._id,
        s_no: item.s_no,
        totalEarnings: item.totalEarnings,
        username: item?.user?.name,
        gender: item?.user?.gender || "null",
        winningamount: item?.user?.winningAmount || "null",
      });
    });

  return (
    <div className="mx-4">
      <div className="flex flex-row mt-8">
        <Button
          lable={"E-sport"}
          width={"w-[80px]"}
          onClick={() => setSelectedTab("GAME")}
        />
        <Button
          lable={"Ludo"}
          width={"w-[80px]"}
          ml={"ml-4"}
          onClick={() => setSelectedTab("LUDO")}
        />
      </div>
      {selectedTab === "GAME" ? (
        topGamePlayer?.length ? (
          <Table
            columns={columns}
            data={topperGameData}
            titleData={tableHeadingGame}
            headingSize={"text-[1rem]"}
          />
        ) : (
          <div className="flex justify-center text-white mt-10">
            No Data Found...
          </div>
        )
      ) : topLudoPlayer?.length > 0 ? (
        <Table
          columns={column}
          data={topperLudoData}
          titleData={tableHeadingLudo}
          headingSize={"text-[1rem]"}
        />
      ) : (
        <div className="flex justify-center text-white mt-10">
          No Data Found...
        </div>
      )}

      <Pagination
    
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default TopPlayer;
