import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGetTransactionList } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";

const TimeFormat = ({ time }) => {
  const date = new Date(time);

  const formattedDate = date.toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  return <div>{formattedDate}</div>;
};

const TransactionList = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const navigate = useNavigate();

  const fetchTransactionData = async () => {
    const res = await handleGetTransactionList(currentPage);
    console.log(res, "TransactionList");
    setTransactionList(res?.data?.transactions);
    setTotalPages(res.data.totalPages)
  };

  useEffect(() => {
    fetchTransactionData();
  }, [currentPage]);

  const handleViewDetailClick = (transactionId) => {
    // Replace with your desired action, e.g., navigation to a detail page
    navigate(`/Transactionlistbyid/${transactionId}`);
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Type", accessor: "type" },
    { Header: "Name", accessor: "name" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Time", accessor: "time" },
    { Header: "View Detail", accessor: "viewdetail" },
    { Header: "Status", accessor: "status" },
  ];

  const transactionData = [];

  transactionList &&
    transactionList.forEach((item, index) => {
      transactionData.push({
        _id: item._id,
        s_no: item.s_no, // Assuming s_no is the index in the array
        type: item.type,
        name: item.userId?.name,
        amount: item.amount,
        time: <TimeFormat time={item.createdAt} />,
        viewdetail: (
          <button
            onClick={() => handleViewDetailClick(item.userId._id)}
            className="bg-blue-500 text-white px-2 py-1 rounded"
          >
            View Detail
          </button>
        ),
        status: item.status,
      });
    });

  const tableHeading = {
    heading: "Transaction",
  };

  return (
    <div>
        <div className="mx-4 pb-4 pt-4">
          <Table
            columns={columns}
            data={transactionData}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
        </div>
     
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default TransactionList;
