import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const WinnerConfirmation = ({ openModal, setOpenModal, callfunction }) => {
  return (
    <Dialog
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="logout-confirmation-dialog-title"
      aria-describedby="logout-confirmation-dialog-description"
    >
      <DialogTitle
        style={{ fontSize: "20px" }}
        id="logout-confirmation-dialog-title"
      >
        CONFIRM WINNER
      </DialogTitle>
      <DialogContent>
        Are you sure you want to declare this user winner?
      </DialogContent>
      <DialogActions>
        <Button onClick={() => callfunction()} color="primary">
          Yes
        </Button>
        <Button onClick={() => setOpenModal(false)} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WinnerConfirmation;
