import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ element }) => {
  // const { authenticated } = useSelector((state) => state.usersList);
  const authenticated = localStorage.getItem("token");

  return <div>{authenticated ? element : <Navigate to="/login" />}</div>;
};

export default ProtectedRoute;
