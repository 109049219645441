import React from "react";

const InputComponent = ({
  lable,
  placeholder,
  value,
  onChange,
  width,
  mlm,
  readOnly = false,
  type,
}) => {
  return (
    <div className={`pt-2 ${width} ${mlm}`}>
      <div className="text-white">{lable}</div>
      <input
        placeholder={placeholder}
        className="border-[1px] border-grey bg-blue text-white px-1 py-[2px] text-[.8rem] rounded-[4px] w-[100%] mt-2 h-[25px] focus:outline-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        readOnly={readOnly}
        type={type}
      />
    </div>
  );
};

export default InputComponent;
