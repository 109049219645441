import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleGetParticipantById } from "../../networks/adminApi";
import Table from "../../components/table";
const ParticipantDetails = () => {
  const { id } = useParams();

  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetParticipantById({ id });
      if (res?.data) {
        setUser(res?.data?.user);
      }
    };
    fetchData();
  }, [id]);

  const ParticipantHeader = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
  ];
  const participantData = [];

  participantData.push({
    name: user?.name,
    email: user?.email,
  });

  const tableHeading = {
    heading: "User",
  };

  return (
    <div className="mx-4">
      {participantData?.length > 0 ? (
        <Table
          columns={ParticipantHeader}
          data={participantData}
          titleData={tableHeading}
        />
      ) : (
        <div className="text-white flex  justify-center mt-10">
          No Data Found...
        </div>
      )}
    </div>
  );
};

export default ParticipantDetails;
