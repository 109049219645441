import React, { useEffect, useState } from "react";
import GraphComponent from "./graphComponent";
import DonutComponent from "./donutComponent";
import Table from "../table";
import { getUsersList } from "../../networks/adminApi";
import { useLocation } from "react-router-dom";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL, Instance } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";

const DashMain = () => {
  const [usersList, setUsersList] = useState([]);
  const location = useLocation();

  const isToken = localStorage.getItem("token");
  console.log(isToken, "istoken");

  useEffect(() => {
    if (isToken) {
      const fetchData = async () => {
        const obj = {};
        try {
          const result = await callAPI(
            BASE_URL + ENDPOINTS_API.GETUSERDETAILS,
            "GET",
            {
              "content-type": "application/json",
            }
          );
          if (result) {
            console.log(result, "result");
            setUsersList(result?.data?.users);
            console.log(result.data.users, "UUUUUUUUUUUUUUUUU");
          }
        } catch (error) {
          return error;
        }
      };
      fetchData();
    } else {
      console.log("nahi chalni chahye");
    }
  }, [isToken]);

  // useEffect(() => {
  //   console.log(location, "jjjj");
  // }, []);

  const detailsExpense = {
    _id: 1,

    value: "239799",
    type: "Total Expenses",
    color: "rgb(177,72,201)",
    bgColor: "rgba(177,72,201,0.2)",
    data: [650, 59, 80, 781, 56, 55, 120, 200, 100, 500, 5],
  };

  const detailsIncome = {
    _id: 1,
    value: "239799",
    type: "Total Expenses",
    color: "rgb(88,172,255)",
    bgColor: "rgba(88,172,255,0.2)",
    data: [50, 9, 80, 781, 56, 55, 20, 200, 10, 900, 5],
  };
  const detailsLoss = {
    _id: 1,
    value: "239799",
    type: "Total Expenses",
    color: "rgb(175,212,100)",
    bgColor: "rgba(175,212,100,0.2)",
    data: [60, 59, 80, 71, 56, 555, 120, 200, 10, 500, 5],
  };
  const matchCounter = {
    _id: 1,
    heading: "Match Counter",
    game: [
      { _id: 1, color: "rgb(232,84,114)", text: "BGMI", value: 100 },
      { _id: 2, color: "rgb(128,6,237)", text: "TDM", value: 50 },
      { _id: 3, color: "rgb(5,164,238)", text: "Sniper TDM", value: 80 },
      { _id: 4, color: "rgb(94,195,12)", text: "454545", value: 20 },
    ],
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "Email", accessor: "email" },
    { Header: "Date of Birth", accessor: "date" },
  ];

  let registrationData = [];

  usersList &&
    usersList?.slice(0, 5).forEach((item) => {
      registrationData.push({
        _id: item.id,
        name: item.name,
        email: item.email,
        mobile: item.mobile,
        date: item.dob ? item.dob : "Not added",
      });
    });

  const redeemData = [
    {
      name: "John Doe",
      mobile: 9988989898,
      email: "john@example.com",
      date: "12/11/12",
    },
    {
      name: "Jane Smith",
      mobile: 9988989898,
      email: "jane@example.com",
      date: "12/11/12",
    },
    {
      name: "Sam Johnson",
      mobile: 9988989898,
      email: "sam@example.com",
      date: "12/11/12",
    },
  ];

  const registrationObj = {
    heading: "New Registration",
    subHeading: "Manage register user here",
    viewAll: "View All",
    path: "/userList",
  };

  const redeemObj = {
    heading: "Recent Redeem Request",
    subHeading: "Manage withdrawal request here",
    viewAll: "View All",
  };
  return (
    <div className="min-w-[300px] w-[100%] mx-2 ">
      <div className="overflow-x-scroll hide-scrollbar ">
        <div className="flex felx-row  w-[100%] ">
          <GraphComponent details={detailsExpense} />
          <GraphComponent details={detailsIncome} />
          <GraphComponent details={detailsLoss} />
          <DonutComponent matchCounter={matchCounter} />
        </div>
      </div>
      {registrationData.length > 0 ? (
        <Table
          columns={columns}
          data={registrationData}
          titleData={registrationObj}
        />
      ) : (
        <div className=" flex justify-center text-white mt-10">
          No Data Found...
        </div>
      )}

      {/* {redeemData.length > 0 ? (
        <Table columns={columns} data={redeemData} titleData={redeemObj} />
      ) : (
        <div className=" flex justify-center text-white mt-10">
            No Data Found...
        </div>
      )} */}
    </div>
  );
};

export default DashMain;
