import React from "react";
import RadarChart from "./radarChart";
import { useContextValue } from "../../hooks/authContext";

const DashSection = ({ showSection }) => {
  const { noOfuser } = useContextValue();
  const sectionData = [
    {
      _id: 1,
      img: require("../../assets/image/dashboard/number.png"),
      number: noOfuser,
      desc: "Total Reg User",
    },
    // {
    //   _id: 2,
    //   img: require("../../assets/image/dashboard/number.png"),
    //   number: "2",
    //   desc: "Total Admin User",
    // },
    // {
    //   _id: 3,
    //   img: require("../../assets/image/dashboard/number.png"),
    //   number: "580",
    //   desc: "Total Withdraw req.",
    // },
  ];

  const colorSymbol = [
    { _id: 1, color: "bg-aquaBlue", text: "Ongoing" },
    { _id: 2, color: "bg-orange", text: "Upcoming" },
    { _id: 3, color: "bg-pink", text: "Completed" },
  ];

  return (
    <div
      className={`w-[200px] sm:relative absolute z-10 bg-blue ${
        showSection ? "block" : "hidden"
      } sm:block pr-2 sm:pr-0 `}
    >
      {sectionData.map((item, index) => {
        return (
          <div
            key={item._id}
            className="flex flex-row items-center bg-darkBlue mt-4"
          >
            <img src={item.img} className="h-20 w-20" />
            <div>
              <div className="text-white text-3xl">{item.number}</div>
              <div className="text-grey text-[.6rem]">{item.desc}</div>
            </div>
          </div>
        );
      })}

      <div className="bg-darkBlue mt-4">
        <div className="flex flex-row justify-between pt-4 mx-2">
          <div>
            <div className="text-white text-[.5rem]">Tournament Summary</div>
            <div className="text-grey text-[.5rem]">72% space uesd</div>
          </div>
          <div>
            {colorSymbol.map((item) => {
              return (
                <div key={item._id} className="flex flex-row items-center">
                  <div className={`${item.color} h-[10px] w-[10px] mr-1`}></div>
                  <div className="text-grey text-[.5rem]">{item.text}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-40">
          <RadarChart />
        </div>
        <div className="text-center text-white text-[.4rem]">Total Matches</div>
        <div className="text-center text-grey text-[1.5rem]">2261</div>
      </div>
    </div>
  );
};

export default DashSection;
