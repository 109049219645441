import React from "react";
import { Radar } from "react-chartjs-2";
import "chart.js/auto"; // Ensure to include this import

const RadarChart = () => {
  const data = {
    labels: ["", "", "", "", ""],
    datasets: [
      {
        label: "Player A",
        data: [80, 90, 70, 85, 60], // Data points for Player A
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
      {
        label: "Player B",
        data: [60, 80, 90, 70, 85], // Data points for Player B
        backgroundColor: "rgba(153,102,255,0.2)",
        borderColor: "rgba(153,102,255,1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scale: {
      pointLabels: {
        display: false, // Hide the labels at the end of each axis
      },
      grid: {
        display: false, // Show grid lines if needed
      },
      ticks: {
        display: false,
      },
    },

    datalabels: {
      display: false, // If you are using chartjs-plugin-datalabels, hide data labels
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },

      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div style={{ height: "200px", width: "200px" }}>
      <Radar data={data} options={options} />
    </div>
  );
};

export default RadarChart;
