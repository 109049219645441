/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Table from "../components/table";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";

const errorchallanges = () => {
  const [errorChallanges, setErrorChallanges] = useState();

  const handleGetErrorchallanges = () => {
    callAPI(BASE_URL + ENDPOINTS_API.ERROR_CHALLANGES, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        setErrorChallanges(res?.data?.challenges);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleGetErrorchallanges();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },

    { Header: "User", accessor: "user" },
    { Header: "Email", accessor: "email" },
    { Header: "Mobile", accessor: "mobile" },
    {
      Header: "Screenshot",
      accessor: "screenshot",
    },
  ];

  const errorchallangesdata = [];

  console.log(errorChallanges, "asdfghjk");
  errorChallanges &&
    errorChallanges.forEach((item, index) => {
      errorchallangesdata.push({
        s_no: index + 1,
        user: item?.createdBy?.name,
        email: item?.createdBy?.email,
        mobile: item?.createdBy?.mobile,
        screenshot: item?.screenshot ? (
          <img
            src={item?.screenshot}
            alt="Screenshot"
            style={{
              height: "50px",
              width: "50px",
              marginLeft:"100px"
             
            }}
          />
        ) : (
          "No img uploaded"
        ),
      });
    });

  const tableHeading = {
    heading: "Error challanges List",
  };

  return (
    <>
      <div>
        <Table
          columns={columns}
          data={errorchallangesdata}
          titleData={tableHeading}
          headingSize={"text[1rem]"}
        />
      </div>
    </>
  );
};

export default errorchallanges;
