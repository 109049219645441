import React from "react";

const InputImage = ({ lable, setFile, setFilePreview, width, value }) => {
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // If you want to preview the file (for images)
    if (selectedFile && selectedFile.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setFilePreview(null);
    }
  };
  return (
    <div className={`pt-2 ${width}`}>
      <div className="text-white">{lable}</div>
      <input
        type="file"
        className="border-[1px] border-grey bg-blue text-white px-1 py-[2px] text-[.8rem] rounded-[4px] w-[100%] mt-2 text-[0.8rem] "
        onChange={handleFileChange}
        value={value}
      />
    </div>
  );
};

export default InputImage;
