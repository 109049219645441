import React, { useState } from "react";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import Button from "../../components/button";
import { handleAddLudoChallenge } from "../../networks/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddLudoChallenge = () => {
  const [roomId, setRoomId] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [ludoType, setLudoType] = useState("");
  const ludoData = ["Duo", "Quad"];

  const [roomIdError, setRoomIdError] = useState("");
  const [entryFeeError, setEntryFeeError] = useState("");
  const [ludoTypeError, setLudoTypeError] = useState("");

  const navigate = useNavigate();

  const ludoValidtion = () => {
    let valid = true;
    const numberOnlyRegex = /^\d+$/;

    if (roomId.trim() === "") {
      valid = false;
      setRoomIdError("Please enter room Id");
    } else {
      setRoomIdError("");
    }

    if (entryFee.trim() === "") {
      valid = false;
      setEntryFeeError("Please enter entry fee");
    } else if (!numberOnlyRegex.test(entryFee)) {
      setEntryFeeError("Please enter number only");
      valid = false;
    } else if (entryFee % 10 !== 0) {
      setEntryFeeError("Entry fee should be multiple of 10");
      valid = false;
    } else {
      setEntryFeeError("");
    }

    if (ludoType.trim() === "") {
      valid = false;
      setLudoTypeError("Please select Ludo type");
    } else {
      setLudoTypeError("");
    }
    return valid;
  };

  const addLudoChallenges = async () => {
    if (ludoValidtion()) {
      const res = await handleAddLudoChallenge({
        roomId,
        entryFee: parseInt(entryFee),
        gameType: ludoType.toLowerCase(),
      });

      if (res?.message === "Challenge created successfully") {
        toast.success("Ludo challenge added successfully");
        navigate("/addLudoList");
      }
    }
  };

  return (
    <div className="mx-4 mt-4">
      <div className="text-white ">Add a New Ludo Challange</div>

      <InputComponent
        lable={"Room Id"}
        placeholder={"Enter Room Id"}
        value={roomId}
        onChange={setRoomId}
        width={"w-[80%]"}
      />
      {roomIdError && <div className="error">{roomIdError}</div>}
      <InputComponent
        lable={"Entry fee"}
        placeholder={"Enter entry fee"}
        value={entryFee}
        onChange={setEntryFee}
        width={"w-[80%]"}
        type={"number"}
      />
      {entryFeeError && <div className="error">{entryFeeError}</div>}
      <SelectComponet
        lable={"Type"}
        value={ludoType}
        onSelect={setLudoType}
        width={"w-[80%]"}
        data={ludoData}
      />
      {ludoTypeError && <div className="error">{ludoTypeError}</div>}
      <div className="mt-4 flex justify-center w-[80%]">
        <Button lable="Add Challenge" onClick={addLudoChallenges} />
      </div>
    </div>
  );
};

export default AddLudoChallenge;
