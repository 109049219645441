import React, { useState } from "react";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import SaveCancelButton from "../../components/saveCancelButton";

const Scratchcard = () => {
  const [noOfMAtch, setNoOfMatch] = useState("");
  const [entryFee, setEntryFee] = useState("");
  const [scratchAmount, setScratchAmount] = useState("");
  const entreeFeeData = ["10-20", "20-50", "50-100", "100-200"];
  const scratchAmountData = ["5-10", "10-20", "20-30"];

  const handleSave = () => {};

  return (
    <div className="w-[80%]">
      <InputComponent
        lable={"Match Played"}
        placeholder={"Enter no of match played"}
        value={noOfMAtch}
        onChange={setNoOfMatch}
        mlm={"ml-2"}
      />
      <SelectComponet
        lable={"Entry Fee"}
        value={entryFee}
        onSelect={setEntryFee}
        mlm={"ml-2"}
        data={entreeFeeData}
      />
      <SelectComponet
        lable={"Scratch Amount"}
        value={scratchAmount}
        onSelect={setScratchAmount}
        mlm={"ml-2"}
        data={scratchAmountData}
      />

      <SaveCancelButton onClickSave={handleSave} />
    </div>
  );
};

export default Scratchcard;
