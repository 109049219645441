import React from "react";
import { Link } from "react-router-dom";

const LinkComponent = ({ path, lable, mt, ml, mr, mb, width }) => {
  return (
    <Link
      to={path}
      className={`bg-gradient-to-r from-primary to-secondary from-cyan-500 to-blue-500 text-white text-[.7rem] px-4 py-[2px] rounded-[4px] ${mt} ${ml} ${mr} ${mb} ${width}`}
    >
      {lable}
    </Link>
  );
};

export default LinkComponent;
