/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import { useParams } from "react-router-dom";

const redeemedcoupon = () => {
  const [redeemedList, setRedeemedList] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
      const { id } = useParams();


  const handleRedeemedList = () => {
    callAPI(BASE_URL + ENDPOINTS_API.REDEEMED_LIST + `/${id}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        console.log(res.data);
        setRedeemedList(res.data?.coupons);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleRedeemedList();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Coupon Id", accessor: "couponid" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Deposit Amount", accessor: "depositamount" },

  ];

  const redeemedData = [];

  redeemedList &&
    redeemedList?.forEach((item, index) => {
      redeemedData.push({
        s_no: item.s_no,
        _id: item._id,
        couponid: item.couponId,
        name: item.userId?.name,
        email: item?.userId?.email,
        depositamount: item?.userId?.depositAmount,
      });
    });

  const tableHeading = {
    heading: "Redeemed Coupon List",
  };

  return (
    <div>
        <div className="mx-4 pb-4 pt-4">
          <Table
            columns={columns}
            data={redeemedData}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
        </div>
       
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default redeemedcoupon;
