/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { getAdminProfile, handleEditAdminProfile } from "../networks/adminApi";
import { useSelector } from "react-redux";
import InputComponent from "../components/inputComponent";
import InputImage from "../components/inputImage";
import { Link } from "react-router-dom";
import Button from "../components/button";
import { toast } from "react-toastify";

const EditProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const { usersProfile } = useSelector((state) => state.usersList);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState(""); // Error state for email
  const [imageError, setImageError] = useState("");

  useEffect(() => {
    setName(usersProfile.name);
    setEmail(usersProfile.email);
  }, [usersProfile]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAdminProfile();
      if (res) {
        setImage(res?.image);
        setEmail(res?.email);
        setName(res?.name);
        setImagePreview(res?.image);
      }
    };
    if (localStorage.getItem("token")) {
      fetchData();
    }
  }, []);

  const createFormData = () => {
    const newData = new FormData();
    if (image) {
      newData.append("image", image);
    }
    newData.append("name", name);
    newData.append("email", email); // Append the email to form data
    return newData;
  };

 const validation = () => {
   let valid = true;

   // Regular expression for email validation
   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

   if (name?.trim() === "" || name === "0") {
     valid = false;
     setNameError("Please enter a valid name");
   }

   if (email?.trim() === "" || email === "0") {
     valid = false;
     setEmailError("Please enter a valid email");
   } else if (!emailPattern.test(email)) {
     valid = false;
     setEmailError("Please enter a valid email address");
   }

   if (!image) {
     valid = false;
     setImageError("Please choose a profile picture");
   }

   return valid;
 };


  const handleEditProfile = async () => {
    const getData = createFormData();

    if (validation()) {
      const res = await handleEditAdminProfile(getData);
      if (res) {
        toast.success("Profile updated successfully");
      } else {
        toast.error("Profile update failed");
      }
    }
  };

  // Update error states as user types or makes changes
  const handleNameChange = (value) => {
    setName(value);
    if (value?.trim() !== "" && value !== "0") {
      setNameError(""); // Clear the error when input is valid
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    if (value?.trim() !== "" && value !== "0") {
      setEmailError(""); // Clear the error when input is valid
    }
  };

  const handleImageChange = (file) => {
    setImage(file);
    if (file) {
      setImageError(""); // Clear the error when an image is selected
    }
  };

  return (
    <div className="mx-4">
      <div className="text-center mt-4 text-white text-[1.2rem] font-bold">
        Edit profile
      </div>
      <div className="w-[80%] flex justify-end">
        <Link to="/updatePassword" className="text-white">
          Update Password
        </Link>
      </div>
      <InputComponent
        lable={"Name"}
        placeholder={"Enter your name"}
        value={name}
        onChange={handleNameChange} // Call the new handler
        width={"w-[80%]"}
      />
      {nameError && <div className="error">{nameError}</div>}
      <InputComponent
        lable={"Email"}
        placeholder={"Enter your email"}
        value={email}
        onChange={handleEmailChange} // Call the new handler
        width={"w-[80%]"}
      />
      {emailError && <div className="error">{emailError}</div>}
      <InputImage
        lable={"Profile Pic"}
        setFile={handleImageChange} // Call the new handler for image
        setFilePreview={setImagePreview}
        width={"w-[80%]"}
      />
      {imageError && <div className="error">{imageError}</div>}
      {imagePreview ? (
        <img
          src={imagePreview}
          className="h-[200px] w-[200px] mt-4 border-[1px] border-white text-white rounded-[100%]"
          alt="Profile picture preview"
        />
      ) : (
        <div className="h-[200px] w-[200px]"></div>
      )}
      <div className="flex justify-center w-[100%] mt-4 pb-4">
        <Button lable={"Update Profile"} onClick={handleEditProfile} />
      </div>
    </div>
  );
};

export default EditProfile;
