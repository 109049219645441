import React, { useState } from "react";
import InputComponent from "../../components/inputComponent";
import Button from "../../components/button";
import { useLocation } from "react-router-dom";
import {
  handleChangePassword,
  handleOTPForgotPasword,
} from "../../networks/adminApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;
  const email = state?.email;

  const validation = () => {
    let valid = true;

    if (otp.trim() === "") {
      valid = false;
    }
    if (password.trim() === "") {
      valid = false;
    }

    if (confirmPassword.trim() === "") {
      valid = false;
    }

    return valid;
  };

  const handlePasswordChange = async () => {
    if (validation()) {
      const res = await handleChangePassword({
        email,
        otp,
        newPassword: password,
        confirmPassword,
      });

      if (res?.data?.message === "password update successfully") {
        navigate("/login");
        toast.success("Password updated successfully");
      } else if (res?.data?.message === "New passwords do not match") {
        toast.error("Confirm Password does not match");
      } else if (res?.data?.message === "wrong otp") {
        toast.error(res.data.message);
      }
    } else {
      toast.warn("Please fill all fields");
    }
  };

  const handleResendOTP = async () => {
    const res = await handleOTPForgotPasword({ email });
    if (res?.data?.success) {
      toast.success("Otp is Resent");
    }
  };

  return (
    <div className="flex items-center bg-blue justify-center absolute w-[100%] h-[100vh] top-0 left-0 z-50">
      <div className="bg-darkBlue p-4 rounded-[10px]">
        <InputComponent
          lable={"OTP"}
          placeholder={"Enter your otp"}
          value={otp}
          onChange={setOtp}
          width={"w-[400px]"}
        />
        <InputComponent
          lable={"Password"}
          placeholder={"Enter your password"}
          value={password}
          onChange={setPassword}
          width={"w-[400px]"}
          type={"password"}
        />
        <InputComponent
          lable={"Confirm Password"}
          placeholder={"Confirm your password"}
          value={confirmPassword}
          onChange={setConfirmPassword}
          width={"w-[400px]"}
          type={"password"}
        />
        <div className="mt-4 flex justify-between">
          <Button lable="Update Password" onClick={handlePasswordChange} />
          <Button lable={"Resend OTP"} onClick={handleResendOTP} />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
