import React, { useEffect, useState } from "react";
import { getAllGame, handleDeleteGameById } from "../../networks/adminApi";
import Table from "../../components/table";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import Pagination from "../../components/pagination";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const CreateEvent = ({ id, name }) => {
  return (
    <div className="flex justify-center">
      <Link to={`/addnewMatch/${name}/${id}`} className="text-white">
        <MdEdit />
      </Link>
    </div>
  );
};

const GetEventComponent = ({ id }) => {
  return (
    <Link to={`/getEvent/${id}`} className="text-white">
      View Events
    </Link>
  );
};

const DeleteGame = ({ id, setDeleteGame }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteById = async () => {
    const res = await handleDeleteGameById({ id });

    if (res?.success) {
      setDeleteGame(true);
      toast.success("Game deleted successfully");
      handleClose();
    } else {
      toast.error("Game delete failed");
    }
  };
  return (
    <>
      <button onClick={handleClickOpen}>
        <MdDelete />
      </button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this game?</p>
        </DialogContent>
        <DialogActions>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#226dcf",
              color: "white",
              cursor: "pointer",
              width: "8vw",
              height: "5vh",
              borderRadius: "5px",
            }}
            onClick={handleDeleteById}
            color="error"
          >
            Delete
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllGames = () => {
  const [allGames, setAllGames] = useState([]);
  const [delteGame, setDeleteGame] = useState(false);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllGame(currentPage);
      setAllGames(res?.data?.games);
      setDeleteGame(false);
      setTotalPages(res?.data?.totalPages)
    };
    fetchData();
  }, [delteGame,currentPage]);

  const columns = [
    { Header: "S.No", accessor: "Sno" },

    { Header: "Title", accessor: "title" },
    { Header: "Game Name", accessor: "name" },
    { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Add Event", accessor: "event" },
    { Header: "Get Event", accessor: "getEvent" },
    { Header: "Delete", accessor: "delete" },
  ];

  let registrationData = [];

  allGames &&
    allGames?.forEach((item) => {
      registrationData.push({
        Sno:item.s_no,
        id: item.id,
        title: item.title,
        name: item.name,
        imgPreview: (
          <div className="flex justify-center">
            <img
              src={item.image}
              className="h-10 w-10 object-contain"
              alt="img"
            />
          </div>
        ),
        event: <CreateEvent id={item.id} name={item.name} />,
        getEvent: <GetEventComponent id={item.id} />,
        delete: <DeleteGame id={item.id} setDeleteGame={setDeleteGame} />,
      });
    });
  const registrationObj = {
    heading: "All Games",
  };
  return (
    <div>
      {registrationData?.length > 0 ? (
        <Table
          columns={columns}
          data={registrationData}
          titleData={registrationObj}
          headingSize={"text-[1rem]"}
        />
      ) : (
        <div className="text-white flex justify-center mt-10">
          No Data Found...
        </div>
      )}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default AllGames;
