import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { handleUserCouponData } from "../networks/adminApi";
import Table from "../components/table";

const CouponList = () => {
  const [coupons, setCoupons] = useState([]);
  const { id } = useParams();
  console.log(id);
  const fetchUserCoupon = async () => {
    const res = await handleUserCouponData({ id });
    console.log(res, "ndindoinwdnqnwqdiwa");
    setCoupons(res?.data?.coupons);
  };
  useEffect(() => {
    fetchUserCoupon();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Redeemed", accessor: "isRedeemed" },
  ];

  const tableHeading = {
    heading: "User Coupon",
  };
  const couponData = [];
  coupons &&
    coupons?.forEach((item) => {
      couponData.push({
        _id: item._id,
        amount: item.amount,
        isRedeemed: item.isRedeemed ? "Redeemed" : "Not Redeemed",
        s_no: item.s_no,
      });
    });

  return (
    <div>
      {coupons.length > 0 ? (
        <div className="mx-4 pb-4">
          <Table
            columns={columns}
            data={couponData}
            titleData={tableHeading}
            headingSize={"text-[1rem]"}
          />
        </div>
      ) : (
        <div className="flex justify-center text-white mt-10">
          No Data Found...
        </div>
      )}
    </div>
  );
};

export default CouponList;
