import React from "react";

const DateComponent = ({
  lable,
  type,
  mt = "mt-2",
  width,
  mlm,
  value,
  onChange,
}) => {
  return (
    <div className={`${mt} ${width} ${mlm}`}>
      <div className="text-white">{lable}</div>
      <input
        type={type}
        className="mt-2 h-[25px] text-[0.8rem] rounded-[4px] bg-blue border-[1px] border-grey text-white w-[100%] pb-[2px] px-2 focus:outline-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default DateComponent;
