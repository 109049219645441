import React, { useEffect, useState } from "react";
import Table from "../components/table";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL_USER } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import { useParams } from "react-router-dom";
import moment from "moment";

const Transaction = () => {
  const [transactionDetail, setTransactionDetail] = useState();

  const { id } = useParams();
  console.log(id);

  useEffect(() => {
    handleTransaction();
  }, [id]);

  const handleTransaction = () => {
    callAPI(
      BASE_URL_USER + ENDPOINTS_API.TRANSACTION_LIST_BY_ID,
      "POST",
      {
      
          "Content-type": "application/json",
        
      },
      JSON.stringify({ userId: id })
    )
      .then((res) => {
        console.log(res.data);
        setTransactionDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
    },
  ];

  const transactionById = [];

  transactionDetail &&
    transactionDetail.forEach((item, index) => {
      transactionById.push({
        _id: item._id,
        s_no: index + 1,
        type: item.type,
        status: item.status,
        amount: item.amount,
        createdAt: moment(item.createdAt).format("DD-MM-YYYY , LT"),
      });
    });

  const tableHeading = {
    heading: "Transaction Details",
  };

  return (
    <>
      <Table
        columns={columns}
        data={transactionById}
        titleData={tableHeading}
        headingSize={"text-[1rem]"}
      />
    </>
  );
};

export default Transaction;
