import React from "react";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
const OffLineTransaction = () => {
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Order Id", accessor: "orderId" },
    { Header: "Register Name", accessor: "regName" },
    { Header: "Coin", accessor: "coin" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Wallet", accessor: "wallet" },
    { Header: "Holders Name", accessor: "holderName" },
    { Header: "User's Mobile Number/Email", accessor: "contact" },
    { Header: "Comment", accessor: "comment" },
    { Header: "Regd.Date", accessor: "regDate" },
    { Header: "Status", accessor: "status" },
    { Header: "Action", accessor: "action" },
  ];

  const bulkActionData = [
    {
      id: 1,
      orderId: "auhahdad",
      regName: "kniadoaod",
      coin: 99,
      amount: 909909,
      wallet: 76776787,
      holderName: "Sawvsrvs",
      contact: "9989989989",
      comment: "wowoowow",
      regDate: "12/12/12",
      status: "active",
      action: "Not Required",
    },
    {
      id: 2,
      orderId: "auhahdad",
      regName: "kniadoaod",
      coin: 99,
      amount: 909909,
      wallet: 76776787,
      holderName: "Sawvsrvs",
      contact: "9989989989",
      comment: "wowoowow",
      regDate: "12/12/12",
      status: "active",
      action: "Not Required",
    },
    {
      id: 3,
      orderId: "auhahdad",
      regName: "kniadoaod",
      coin: 99,
      amount: 909909,
      wallet: 76776787,
      holderName: "Sawvsrvs",
      contact: "9989989989",
      comment: "wowoowow",
      regDate: "12/12/12",
      status: "active",
      action: "Not Required",
    },
  ];

  const matchListObj = {
    heading: "Match List",
    subHeading:
      "Complete match list here, Denerate result, position and winner",
    viewAll: "View All",
  };
  return (
    <div>
      <div className="flex flex-row items-center justify-between mx-4 pt-4 mb-4">
        <button className="bg-gradient-to-r from-primary to-secondary from-cyan-500 to-blue-500 text-white text-[.7rem] px-4 py-[3px] rounded-[4px]">
          Add
        </button>
        <img
          src={require("../assets/image/search.png")}
          className="h-3 w-3"
          alt="search"
        />
      </div>
      <div className="flex flex-row mx-4 mb-4">
        <input
          placeholder="Type Keywords..."
          className="border-[1px] border-grey bg-blue rounded-[6px] px-2 py-[1px]"
        />
        <div className="ml-2 relative flex items-center">
          <input
            placeholder="Sort by First Name"
            className="border-[1px] border-grey bg-blue rounded-[6px] px-2 py-[1px]"
          />
          {false ? (
            <FaChevronUp className="absolute right-2 text-white" />
          ) : (
            <FaChevronDown className="absolute right-2 text-white" />
          )}
        </div>
      </div>
      <div className="mx-4">
        <Table
          titleData={matchListObj}
          columns={columns}
          data={bulkActionData}
        />
      </div>
      {/* <Pagination /> */}
    </div>
  );
};

export default OffLineTransaction;
