export const callAPI = async (url, method, requestHeaders, body) => {
  console.log(body, "body");
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("token");
    const userToken = token;
    console.log(userToken, "xdcvb");

    requestHeaders["Authorization"] = `Bearer ${userToken}`;

    fetch(url, {
      method: method,
      headers: requestHeaders,
      body: body,
    })
      .then((response) => {
        let statusCode = +response.status;

        console.log("statusCode", statusCode);
        console.log("NetworkConnection.js Raw Response -->", response);
        console.log(statusCode, "statusCode");
        if (statusCode < 300) {
          // If success than execute other response.
          resolve(response.json());
        } else {
          if (statusCode === 401 || statusCode === 403) {
            console.log("llll");
            localStorage.clear();
            window.location.href = "/login";
            // if (!url.includes("login")) {
            //   setTimeout(() => {

            //   }, 1000);
            // }
          }
          if (statusCode === 444) {
            window.location.href = "/dashboard";
          }
          reject(response.json());
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};
