import React, { useEffect, useState } from "react";
import Table from "../../components/table";
import {
  handleGetChallengeById,
  handleTransferAmount,
  handleUpdateWinner,
} from "../../networks/adminApi";
import { useParams } from "react-router-dom";
import Button from "../../components/button";
import { handleResendAmount } from "../../networks/adminApi";
import { useNavigate } from "react-router-dom";
import WinnerConfirmation from "../../components/modals/winnerConfirmation";
import InputComponent from "../../components/inputComponent";
import RefundModal from "../../components/modals/refundModal";
import { toast } from "react-toastify";

const TransferAmountComponent = ({ id, value, onChange }) => {
  return (
    <InputComponent
      value={value}
      onChange={(e) => onChange(id, "transferAmount", e)}
      type={"number"}
    />
  );
};

const GetViewDetailsGame = () => {
  const { id } = useParams();
  const [participants, setParticipants] = useState([]);
  const [openWinnerModal, setOpenWinnerModal] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState("");
  const [priceMoney, setPriceMoney] = useState("");
  const [winnerId, setWinnerId] = useState("");
  const [inputs, setInputs] = useState({});
  const [entryFee, setEntryFee] = useState();

  const navigate = useNavigate();

  const handleGetDetails = async () => {
    const res = await handleGetChallengeById({ id });
    console.log(res?.data, "Challenges");
    if (res?.data?.participants) {
      setParticipants(res?.data?.participants);
      setEntryFee(res?.data?.entryFee);
      setPriceMoney(res?.data?.prizeMoney);
    }
    if (res?.data?.winner) {
      setWinnerId(res?.data?.winner);
    }
  };
  useEffect(() => {
    handleGetDetails();
  }, []);

  const handleInputChange = (_id, item, value) => {
    if (value?.target?.value <= 0) {
      toast.error("Amount must be greater than 0");
      return;
    }

    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
  };

  const transferAmount = async () => {
    // Check if amount is valid before submitting
    if (inputs?.[selectedUserId]?.transferAmount <= 0) {
      toast.error("Transfer amount must be greater than 0");
      return;
    }

    const res = await handleTransferAmount({
      userId: selectedUserId,
      challengeId: id,
    });
    console.log(res, "Transfer Amount");
    if (res.success) {
      navigate(-1);
      setOpenWinnerModal(false);
    }
  };

  const handlefunc = (id) => {
    console.log(id, "chalala");
    setOpenWinnerModal(true);
    setSelectedUserId(id);
  };

  const refundConfirmation = async () => {
    const amount = inputs?.[selectedParticipant]?.transferAmount;

    // Check if amount is provided and greater than 0
    if (!amount || amount <= 0) {
      toast.error("Refund amount must be greater than 0");
      setOpenRefundModal(false);
      return;
    }

    const res = await handleResendAmount({
      amount: amount,
      userId:id
    });
    console.log(res, "REFUND");
    if (res?.success) {
      navigate(-1);
      setOpenRefundModal(false);
    }
  };

  const SelectTypeTransfer = ({
    userId,
    participant_Id,
    screenshot,
    winnerId,
  }) => {
    return (
      <div className="flex felx-row">
        <Button
          lable={"Refund"}
          onClick={() => [
            setOpenRefundModal(true),
            setSelectedParticipant(participant_Id),
          ]}
          // disabled={
          //   screenshot === undefined
          //     ? "true"
          //     : winnerId === null || winnerId === undefined
          //     ? false
          //     : true
          // }
        />
        <Button
          lable={"Transfer"}
          ml={"ml-2"}
          onClick={() => handlefunc(userId)}
          // disabled={
          //   screenshot === undefined
          //     ? "true"
          //     : winnerId === null || winnerId === undefined
          //     ? false
          //     : true
          // }
        />
      </div>
    );
  };

  const columns = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Event Type", accessor: "eventtype" },
    { Header: "Entry Fee", accessor: "entryfee" },
    { Header: "Winning Amount", accessor: "winningamount" },

    { Header: "User", accessor: "user" },
    { Header: "Email", accessor: "email" },
    {
      Header: "Screenshot",
      accessor: "screenshot",
    },

    { Header: "Transfer Amount", accessor: "transferAmount" },
    { Header: "Winner", accessor: "winner" },
    { Header: "Action", accessor: "refund" },
  ];

  const participantData = [];

  participants &&
    participants.forEach((item, index) => {
      participantData.push({
        s_no: index + 1,

        user: item?.user?.name,
        eventtype: item?.user?.name,

        entryfee: entryFee,
        winningamount: priceMoney,
        email: item?.user?.email,
        screenshot: item?.screenshot ? (
          <img
            src={item?.screenshot}
            alt="Screenshot"
            style={{ height: "50px", width: "50px",marginLeft:"50px" }}
          />
        ) : (
          "No img uploaded"
        ),

        winner:
          winnerId === item._id
            ? "won"
            : winnerId === ""
            ? "Not Selected"
            : "Loss",
        transferAmount: (
          <TransferAmountComponent
            id={item._id}
            value={inputs[item._id]?.transferAmount || item?.entryFee || ""}
            onChange={(e, item, value) => handleInputChange(e, item, value)}
          />
        ),

        refund: (
          <SelectTypeTransfer
            userId={item?.user?._id}
            participant_Id={item._id}
            screenshot={item?.screenshot}
            winnerId={winnerId}
          />
        ),
      });
    });

  return (
    <div>
      <div>
        <button
          style={{
            padding: "3px 8px",
            border: "1px solid",
            background: "#2657cf",
            color: "white",
            borderRadius: "5px",
            marginTop: "1rem",
            marginLeft: "10px",
          }}
          onClick={() => navigate("/errorchallanges")}
        >
          Error challanges
        </button>
      </div>
      <Table
        columns={columns}
        data={participantData}
        headingSize={"text[1rem]"}
      />
      <WinnerConfirmation
        openModal={openWinnerModal}
        setOpenModal={setOpenWinnerModal}
        callfunction={() => transferAmount()}
      />
      <RefundModal
        openModal={openRefundModal}
        setOpenModal={setOpenRefundModal}
        callfunction={() => refundConfirmation()}
      />
    </div>
  );
};

export default GetViewDetailsGame;
