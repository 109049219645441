import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../networks/adminApi";

const UserDetails = () => {
  const { id } = useParams();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await getUserDetails({ id });
  //   };

  //   fetchData();
  // }, []);
  return <div></div>;
};

export default UserDetails;
