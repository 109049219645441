import React, { useState } from "react";

import { IoEye, IoEyeOff } from "react-icons/io5";

const PasswordInputComponent = ({
  lable,
  placeholder,
  value,
  onChange,
  width,
  mlm,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={`pt-2 ${width} ${mlm}`}>
      <div className="text-white">{lable}</div>
      <div className="relative flex items-center  ">
        <input
          placeholder={placeholder}
          className="border-[1px] border-grey bg-blue text-white px-1 py-[2px] text-[.8rem] rounded-[4px] w-[100%] mt-2 h-[25px] focus:outline-none"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type={showPassword ? "text" : "password"}
        />
        <button
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-2 top-[12px] text-white"
        >
          {showPassword ? <IoEye /> : <IoEyeOff />}
        </button>
      </div>
    </div>
  );
};

export default PasswordInputComponent;
