import React, { useState } from "react";
import InputComponent from "../../components/inputComponent";
import Button from "../../components/button";
import { useNavigate } from "react-router-dom";
import { handleOTPForgotPasword } from "../../networks/adminApi";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const validation = () => {
    let valid = true;

    if (email.trim() === "") {
      valid = false;
    }

    return valid;
  };

const handelSendOtp = async () => {
  if (validation()) {
    try {
      const res = await handleOTPForgotPasword({ email });
      if (res?.data?.success) {
        navigate("/chanagePassword", { state: { email } });
        toast.success("Otp Sent Successfully");
      } else {
        toast.error(res?.data?.message || "Failed to send OTP");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred");
    }
  } else {
    toast.error("Please enter a email address");
  }
};

  return (
    <div className="flex items-center bg-blue justify-center absolute w-[100%] h-[100vh] top-0 left-0 z-50">
      <div className="bg-darkBlue p-4 rounded-[10px]">
        <InputComponent
          lable={"Email"}
          placeholder={"Enter your email"}
          value={email}
          onChange={setEmail}
          width={"w-[400px]"}
        />

        <Button lable={"Send OTP"} onClick={handelSendOtp} mt={"mt-4"} />
      </div>
    </div>
  );
};

export default ForgotPassword;
