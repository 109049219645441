/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import InputComponent from "../../components/inputComponent";
import InputImage from "../../components/inputImage";
import Button from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { handleBannerEdit } from "../../networks/adminApi";
import { toast } from "react-toastify";
import { ENDPOINTS_API } from "../../networks/apiConstants";
import { BASE_URL, BASE_URL_USER } from "../../commonservice/commonservice";
import { callAPI } from "../../networks/callApi";

const EditBanner = () => {
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [editData, setEditData] = useState();
  const navigate = useNavigate();

  const { id } = useParams();

  const bannerFormDAta = () => {
    const newData = new FormData();
    newData.append("url", url);
    newData.append("id", id);
    if (image) {
      newData.append("image", image);
    }
    return newData;
  };

  const handleEdit = async () => {
    console.log("ejeejkk");
    const getData = bannerFormDAta();
    const res = await handleBannerEdit(getData);
    if (res.success) {
      toast.success("Banner edited successfully");
      bannerFormDAta();
      navigate("/game", { state: { yourStateValue: "Banner" } });
    }
  };

  const handleBannergetbyid = () => {
    callAPI(`${BASE_URL_USER + ENDPOINTS_API.BANNER_GET_BY_ID}/${id}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        console.log(res.data);
        setEditData(res.data);
        setUrl(res.data?.url);
        setImagePreview(res.data?.image);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleBannergetbyid();
  }, []);

  return (
    <div className="mx-4">
      <InputComponent
        lable={"URL"}
        placeholder={"Enter Url"}
        value={url}
        onChange={setUrl}
        width={"w-[80%]"}
      />
      <InputImage
        lable={"Image"}
        setFile={setImage}
        setFilePreview={setImagePreview}
        width={"w-[80%]"}
      />

      {imagePreview && (
        <img
          src={imagePreview}
          className="h-20 w-20 border-[1px] border-white mt-4"
        />
      )}

      <div className="w-[80%] mt-4">
        <Button lable={"Edit Banner"} onClick={() => handleEdit()} />
      </div>
    </div>
  );
};

export default EditBanner;
