import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import Button from "../components/button";
import {
  handleEditCouponByAdmin,
  handleGetCouponByID,
} from "../networks/adminApi";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

const EditCoupon = () => {
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [matchCounts, setMatchCounts] = useState("");
   const [minAmountError, setMinAmountError] = useState("");
   const [maxAmountError, setMaxAmountError] = useState("");
   const [matchCountsError, setMatchCountsError] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGetCouponByID({
        id,
      });

      if (res?.success) {
        setMinAmount(res?.data?.minAmount);
        setMaxAmount(res?.data?.maxAmount);
        setMatchCounts(res?.data?.matchCounts);
      }
    };

    fetchData();
  }, []);

  const validation = () => {
    let valid = true;
    const numberOnlyRegex = /^\d+$/;

    // Validate Min Amount
    if (minAmount === "") {
      valid = false;
      setMinAmountError("Please enter Minimum amount");
    } else if (!numberOnlyRegex.test(minAmount) || Number(minAmount) <= 0) {
      setMinAmountError("Please enter a valid number greater than 0");
      valid = false;
    } else {
      setMinAmountError("");
    }

    // Validate Max Amount
    if (maxAmount === "") {
      valid = false;
      setMaxAmountError("Please enter Maximum amount");
    } else if (!numberOnlyRegex.test(maxAmount) || Number(maxAmount) <= 0) {
      setMaxAmountError("Please enter a valid number greater than 0");
      valid = false;
    } else {
      setMaxAmountError("");
    }

    // Validate Match Counts
    if (matchCounts === "") {
      valid = false;
      setMatchCountsError("Please enter match counts");
    } else if (!numberOnlyRegex.test(matchCounts) || Number(matchCounts) <= 0) {
      valid = false;
      setMatchCountsError("Please enter a valid number greater than 0");
    } else {
      setMatchCountsError("");
    }

    return valid;
  };
  const handleEditCoupon = async () => {
    if (validation()) {
        if (Number(minAmount) >= Number(maxAmount)) {
        toast.warn("Minimum amount should be less than Maximum amount");
      } else {
      const res = await handleEditCouponByAdmin({
        id,
        maxAmount,
        minAmount,
        matchCounts,
      });
      if (res?.success) {
        toast.success("Coupon Edited successfully");
        navigate("/coupon");
      } else {
        toast.error("Coupon Edit failed");
      }
    }
  };
}

  return (
    <div className="mx-4 mt-4">
      <div className="text-white text-[1.3rem]">Edit Coupon</div>
      <InputComponent
        lable={"Min Amount"}
        placeholder={"Enter minimum amount"}
        value={minAmount}
        onChange={setMinAmount}
        width={"w-[80%]"}
        type={"number"}
      />
      {minAmountError && <div className="error">{minAmountError}</div>}

      <InputComponent
        lable={"Max Amount"}
        placeholder={"Enter maximum amount"}
        value={maxAmount}
        onChange={setMaxAmount}
        width={"w-[80%]"}
        type={"number"}
      />
      {maxAmountError && <div className="error">{maxAmountError}</div>}

      <InputComponent
        lable={"Match count"}
        placeholder={"Enter match Count"}
        value={matchCounts}
        onChange={setMatchCounts}
        width={"w-[80%]"}
        type={"number"}
      />
      {matchCountsError && <div className="error">{matchCountsError}</div>}

      <div className="w-[80%] flex justify-center mt-4">
        <Button lable={"Edit Coupon"} onClick={handleEditCoupon} />
      </div>
    </div>
  );
};

export default EditCoupon;
