import React from "react";
import Button from "./button";

const SaveCancelButton = ({ onClickSave, onClickCancel }) => {
  return (
    <div className="mt-4 flex justify-end">
      <Button lable={"Save"} onClick={onClickSave} />
      <Button lable={"Cancel"} ml={"ml-2"} onClick={onClickCancel} />
    </div>
  );
};

export default SaveCancelButton;
