import React from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

const DonutComponent = ({ matchCounter }) => {
  const lableArray = [];
  const colorArray = [];
  const dataArray = [];
  matchCounter.game.map((item) => lableArray.push(item.text));
  matchCounter.game.map((item) => colorArray.push(item.color));
  matchCounter.game.map((item) => dataArray.push(item.value));
  const data = {
    labels: lableArray,
    datasets: [
      {
        label: "My Dataset",
        data: dataArray, // Data points
        backgroundColor: colorArray,
        borderColor: colorArray,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
  };

  return (
    <div className="bg-darkBlue mt-4 ml-2 flex flex-row h-32 p-2">
      <div className="w-[70px]">
        <div className="ml-2 mt-2 text-white text-[.5rem]">
          {matchCounter.heading}
        </div>
        {matchCounter.game.map((item) => {
          return (
            <div
              key={item._id}
              className="flex flex-row items-center mt-1 ml-2"
            >
              <div
                className={`h-[10px] w-[10px]`}
                style={{ backgroundColor: item.color }}
              ></div>
              <div className="text-[.5rem] ml-1 text-white">{item.text}</div>
            </div>
          );
        })}
      </div>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default DonutComponent;
