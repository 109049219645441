import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usersProfile: {},
  authenticated: false,
};
const usersProfileSlice = createSlice({
  name: "userProfile/Slice",
  initialState,
  reducers: {
    setUsersProfile: (state, action) => {
      state.usersProfile = action.payload;
    },
    setAdminAuthenticated: (state, action) => {
      state.authenticated = action.payload;
    },
  },
});

export default usersProfileSlice.reducer;
export const { setUsersProfile, setAdminAuthenticated } =
  usersProfileSlice.actions;
