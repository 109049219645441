import React from "react";
import { Link } from "react-router-dom";

const Table = ({ titleData, columns, data, headingSize }) => {
  return (
    <div className="overflow-x-auto bg-darkBlue mt-2 pt-2 rounded-[10px]  ">
      {titleData && (
        <div className="flex flex-row justify-between items-center mx-4 my-2">
          <div>
            <div
              className={`text-white ${
                headingSize ? headingSize : "text-[.6rem]"
              } `}
            >
              {titleData.heading}
            </div>
            <div className="text-grey text-[.4rem]">{titleData.subHeading}</div>
          </div>
          {titleData.viewAll && (
            <button>
              <div className="text-white text-[.5rem] border-b-[1px] pb-[2px]">
                <Link to={titleData.path}>{titleData.viewAll}</Link>
              </div>
            </button>
          )}
        </div>
      )}
      <table className="min-w-full">
        <thead className="bg-lightBlue">
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                className="py-2 px-12 text-center text-xs font-semibold text-white uppercase tracking-wider"
              >
                {column.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="even:bg-gray-50">
                {columns.map((column) => (
                  <td
                    key={column.accessor}
                    className="py-2 px-4 border-b-2 border-lightBlue text-center text-white"
                  >
                    {Array.isArray(row[column.accessor]) ? (
                      <ul>
                        {row[column.accessor].map((subItem, idx) => (
                          <li key={idx}>{subItem}</li>
                        ))}
                      </ul>
                    ) : (
                      row[column.accessor]
                    )}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="py-2 px-4 text-center text-white"
              >
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
