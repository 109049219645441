import React, { useState } from "react";
import InputComponent from "../components/inputComponent";
import Button from "../components/button";
import { handleAddCouponByAdmin } from "../networks/adminApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCoupon = () => {
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [matchCounts, setMatchCounts] = useState("");
  const [minAmountError, setMinAmountError] = useState("");
  const [maxAmountError, setMaxAmountError] = useState("");
  const [matchCountsError, setMatchCountsError] = useState("");

  const navigate = useNavigate();

  const validation = () => {
    let valid = true;
    const numberOnlyRegex = /^\d+$/;

    // Validate Min Amount
    if (minAmount === "") {
      valid = false;
      setMinAmountError("Please enter Minimum amount");
    } else if (!numberOnlyRegex.test(minAmount) || Number(minAmount) <= 0) {
      setMinAmountError("Please enter a valid number greater than 0");
      valid = false;
    } else {
      setMinAmountError("");
    }

    // Validate Max Amount
    if (maxAmount === "") {
      valid = false;
      setMaxAmountError("Please enter Maximum amount");
    } else if (!numberOnlyRegex.test(maxAmount) || Number(maxAmount) <= 0) {
      setMaxAmountError("Please enter a valid number greater than 0");
      valid = false;
    } else {
      setMaxAmountError("");
    }

    // Validate Match Counts
    if (matchCounts === "") {
      valid = false;
      setMatchCountsError("Please enter match counts");
    } else if (!numberOnlyRegex.test(matchCounts) || Number(matchCounts) <= 0) {
      valid = false;
      setMatchCountsError("Please enter a valid number greater than 0");
    } else {
      setMatchCountsError("");
    }

    return valid;
  };

  const handleAddCoupon = async () => {
    if (validation()) {
      // Check if Min Amount is less than Max Amount
      if (Number(minAmount) >= Number(maxAmount)) {
        toast.warn("Minimum amount should be less than Maximum amount");
      } else {
        const res = await handleAddCouponByAdmin({
          maxAmount,
          minAmount,
          matchCounts,
        });
        if (res?.success) {
          toast.success("Coupon added successfully");
          navigate("/coupon");
        } else {
          toast.error("Coupon didn't add");
        }
      }
    }
  };

  return (
    <div className="mx-4 mt-4">
      <div className="text-white text-[1.3rem]">Add Coupon</div>

      {/* Min Amount Input */}
      <InputComponent
        lable={"Min Amount"}
        placeholder={"Enter minimum amount"}
        value={minAmount}
        onChange={setMinAmount}
        width={"w-[80%]"}
        type="number"
        min="1" // Ensure no negative or zero values can be entered
      />
      {minAmountError && <div className="error">{minAmountError}</div>}

      {/* Max Amount Input */}
      <InputComponent
        lable={"Max Amount"}
        placeholder={"Enter maximum amount"}
        value={maxAmount}
        onChange={setMaxAmount}
        width={"w-[80%]"}
        type="number"
        min="1" // Ensure no negative or zero values can be entered
      />
      {maxAmountError && <div className="error">{maxAmountError}</div>}

      {/* Match Counts Input */}
      <InputComponent
        lable={"Match count"}
        placeholder={"Enter match Count"}
        value={matchCounts}
        onChange={setMatchCounts}
        width={"w-[80%]"}
        type="number"
        min="1" // Ensure no negative or zero values can be entered
      />
      {matchCountsError && <div className="error">{matchCountsError}</div>}

      {/* Add Coupon Button */}
      <div className="w-[80%] flex justify-center mt-4">
        <Button lable={"Add Coupon"} onClick={handleAddCoupon} />
      </div>
    </div>
  );
};

export default AddCoupon;
