import React, { useEffect, useState } from "react";
import { handleGetUserNotification } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";

const AnnouncementShowuser = () => {
  const [showUser, setShowUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  console.log(showUser, "oooooooooooooooooooo");

  const fetchuserNotification = async () => {
    const res = await handleGetUserNotification(currentPage);
    if (res) {
      setShowUser(res?.data?.users);
      setTotalPages(res.data.totalPages);
    }
  };

  useEffect(() => {
    fetchuserNotification();
  }, [currentPage]);

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "Name", accessor: "name" },
    { Header: "Gender", accessor: "gender" },
    { Header: "UserId", accessor: "userid" },
    { Header: "Mobile Number", accessor: "mobilenumber" },
    { Header: "Referal Id", accessor: "referalId" },
  ];
  const tableHeading = {
    heading: "Announcement User List",
  };

  const announcementData = [];

  showUser &&
    showUser?.forEach((item) => {
      announcementData.push({
        _id: item._id,
        Sno: item.s_no,
        name: item.name,
      });
    });

  return (
    <div>
      <div className="bg-darkBlue">
        <Table
          columns={columns}
          data={announcementData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default AnnouncementShowuser;
