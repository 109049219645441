import React, { useEffect, useState } from "react";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import InputImage from "../../components/inputImage";
import Button from "../../components/button";
import { handleAddBanner, handleAddGame } from "../../networks/adminApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const AddNewGame = () => {

  const [game, setGame] = useState("");
  const [type, setType] = useState("");
  const [url, seturl] = useState("");
  const [name, setName] = useState("");

  const [selectedType, setSelectedType] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageFilePreview, setImageFilePreview] = useState(null);
  const [bannerLocation, setBannerLocation] = useState("");
  const navigate = useNavigate();

  const { add } = useParams();

  const [urlError, setUrlError] = useState("");
  const [imageError, setImageError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [gameError, setGameError] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    setType(add);
  }, [add]);



  const locationData = ["Home", "Ludo"];

  const typeData = ["E-sport", "Banner"];

  const gameformDataFunction = () => {
    const newData = new FormData();

    newData.append("title", game);
    newData.append("type", type);
    newData.append("name", name);

    if (imageFile) {
      newData.append("image", imageFile);
    }

    return newData;
  };

  const bannerFormDAta = () => {
    const newData = new FormData();
    newData.append("type", bannerLocation);
    newData.append("url", url);
    if (imageFile) {
      newData.append("image", imageFile);
    }
    return newData;
  };

  const gameValidation = () => {
    let valid = true;
    if (game.trim() === "") {
      valid = false;
      setGameError("Please enter game title");
    } else {
      setGameError("");
    }

    if (type.trim() === "") {
      valid = false;
      setTypeError("Please enter type");
    } else {
      setTypeError("");
    }
    if (name.trim() === "") {
      valid = false;
      setNameError("Please enter game name");
    } else {
      setNameError("");
    }

    if (imageFile === null) {
      valid = false;
      setImageError("Please choose a image");
    } else {
      setImageError("");
    }

    return valid;
  };

  const bannerValidation = () => {
    let valid = true;
    if (url.trim() === "") {
      valid = false;
      setUrlError("Please enter url");
    } else {
      setUrlError("");
    }
    if (imageFile === null) {
      valid = false;
      setImageError("Please upload image");
    } else {
      setImageError("");
    }
    return valid;
  };

  const handleGameAdd = async () => {
    if (type === "E-sport") {
      if (gameValidation()) {
        const dataObj = gameformDataFunction();
        if (dataObj) {
          const res = await handleAddGame(dataObj);
          if (res?.success) {
            toast.success("Game added successfully");
            navigate("/game");
          }
        }
      }
    }

    if (type === "Banner") {
      if (bannerValidation()) {
        const getData = bannerFormDAta();
        if (getData) {
          const res = await handleAddBanner(getData);
          if (res.success) {
            toast.success("Banner Added successfully");
            navigate("/game");
          }
        }
      }
    }

    if (type === "") {
      toast.warn("Pease select a type");
    }
  };

  return (
    <div className="mx-4 pt-2">
      <div className="text-white">ADD NEW GAME OR BANNER</div>
      <div className="text-grey text-[.7rem]">
        You can manage game or banner here.
      </div>

      {type !== "Banner" && (
        <>
          <InputComponent
            lable={"Title*"}
            placeholder={"Enter title"}
            value={game}
            onChange={setGame}
          />
          {gameError && <div className="error">{gameError}</div>}
        </>
      )}

      <div className="relative">
        <InputComponent
          lable={"Type*"}
          placeholder={"Enter Type"}
          value={type}
          onChange={setType}
          readOnly={true}
        />
      </div>
      {type === "E-sport" && (
        <>
          <InputComponent
            lable={"Name*"}
            placeholder={"Enter game name"}
            value={name}
            onChange={setName}
          />
          {nameError && <div className="error">{nameError}</div>}
        </>
      )}
      {type === "Banner" && (
        <>
          <SelectComponet
            lable={"Choose Banner Location"}
            value={bannerLocation}
            onSelect={setBannerLocation}
            data={locationData}
          />
          <InputComponent
            lable={"Url or Package*"}
            placeholder={"Enter url or package"}
            value={url}
            onChange={seturl}
          />
          {urlError && <div className="error">{urlError}</div>}
        </>
      )}
      <InputImage
        lable={"Image *"}
        setFile={setImageFile}
        setFilePreview={setImageFilePreview}
      />
      <p style={{ color: "white", fontSize: "12px" }}>
        Note : Image size should be between 400 x 130 pixels{" "}
      </p>
      {imageError && <div className="error">{imageError}</div>}

      {imageFilePreview && (
        <img
          src={imageFilePreview}
          className="h-40 w-40 border-[1px] border-white rounded-[10px] mt-4"
        />
      )}
      <div className="mt-4 flex justify-center pb-4">
        <Button
          lable={type === "Banner" ? "Add Banner" : "Add Game"}
          onClick={handleGameAdd}
        />
      </div>
    </div>
  );
};

export default AddNewGame;
